import React from 'react';

import { Icon } from '@bloobirds-it/flamingo-ui';
import clsx from 'clsx';

import styles from '../../../playgroundPages.module.css';

export type ResultStatusType = 'SUCCESS' | 'PARTIAL_SUCCESS' | 'FAILURE' | 'PENDING';
export const ResultStatus = {
  SUCCESS: 'SUCCESS' as ResultStatusType,
  PARTIAL_SUCCESS: 'PARTIAL_SUCCESS' as ResultStatusType,
  FAILURE: 'FAILURE' as ResultStatusType,
  PENDING: 'PENDING' as ResultStatusType,
};

export type InsightType = 'DECISION' | 'EXTRACTION' | 'GENERATION';
export const InsightType = {
  DECISION: 'DECISION' as InsightType,
  EXTRACTION: 'EXTRACTION' as InsightType,
  GENERATION: 'GENERATION' as InsightType,
};

const getResult = (status: ResultStatusType) => {
  if (status === ResultStatus.SUCCESS) {
    return <Icon name="checkDouble" color="melon" />;
  }
  if (status === ResultStatus.PARTIAL_SUCCESS) {
    return <Icon name="check" color="banana" />;
  }
  if (status === ResultStatus.FAILURE) {
    return <Icon name="cross" color="tomato" />;
  }
  return <Icon name="minus" color="softPeanut" />;
};

const generateDecisionChips = (activityResults, picklistField) => {
  const values = picklistField?.values.map(pv => ({
    text: pv.value,
    active: activityResults?.result?.includes(pv.id),
  }));
  return values?.map(value => {
    const classes = clsx(styles.testRun_activities_resultChip, {
      [styles.notSelected]: !value.active,
    });
    return (
      <div key={value.text} className={classes}>
        {value.text}
      </div>
    );
  });
};

const generateExtractionChips = (activityResults, selectedInsight) => {
  const allValues = selectedInsight?.searchWords.split(',').map(word => {
    const containsValue = activityResults?.result?.includes(word);
    const classes = clsx(styles.testRun_activities_resultChip, {
      [styles.notSelected]: !containsValue,
    });
    return (
      <div key={word} className={classes}>
        {word}
      </div>
    );
  });
  return allValues;
};

export { getResult, generateDecisionChips, generateExtractionChips };
