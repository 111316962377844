import React, { useState } from 'react';

import {
  Button,
  Input,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  ModalTitle,
  Spinner,
  Text,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { api } from '@bloobirds-it/utils';

import useSendToPlaygroundModal from '../../hooks/useSendToPlaygroundModal';
import styles from './sendToPlaygroundModal.module.css';

const NoTranscriptActivities = () => {
  return (
    <>
      <Text align="center" size="m">
        You have not selected any activities with a <strong>Transcription</strong>.
      </Text>
      <Text align="center" size="m">
        It is a requirement in order to create a test set in the playground
      </Text>
      <br />
      <Text align="center" size="m">
        💡Tip: Try some activity filters and columns to find them!
      </Text>
    </>
  );
};

const PartialSet = ({
  activitiesWithTranscript,
  activitiesWithoutTranscript,
  type,
}: {
  activitiesWithTranscript: number;
  activitiesWithoutTranscript: number;
  type: string;
}) => {
  return (
    <>
      <Text size="m">
        Some of the activities that you’ve selected do not have <strong>TRANSCRIPT</strong>
      </Text>
      <br />
      <Text size="m">
        ✅ {activitiesWithTranscript}{' '}
        {type.toLowerCase() + (activitiesWithTranscript > 1 ? 's' : '')} will be sent to the
        Playground
      </Text>
      <Text size="m">
        ❌ {activitiesWithoutTranscript}{' '}
        {type.toLowerCase() + (activitiesWithoutTranscript > 1 ? 's' : '')} won't be sent
      </Text>
      <br />
      <Text size="m">Sent activities will create a unique test set.</Text>
      <Text size="m">Do you wish to continue?</Text>
    </>
  );
};

const CorrectSet = ({
  activitiesWithTranscript,
  type,
}: {
  activitiesWithTranscript: number;
  type: string;
}) => {
  return (
    <>
      <Text size="m">Selected activities will create a unique test set in the playground.</Text>
      <br />
      <Text size="m">
        ✅ {activitiesWithTranscript}{' '}
        {type.toLowerCase() + (activitiesWithTranscript > 1 ? 's' : '')} will be sent to the
        Playground
      </Text>
      <br />
      <Text size="m">Do you wish to continue?</Text>
    </>
  );
};

const TypeMismatch = () => {
  return (
    <>
      <Text size="m" align="center">
        You have selected <strong>different types</strong> of activity.
      </Text>
      <Text size="m" align="center">
        Remember, to create a test you should select:
      </Text>
      <br />
      <Text size="m" align="center">
        ☎️ A list of calls
      </Text>
      <Text size="m" align="center">
        📆 A list of meetings
      </Text>
    </>
  );
};

const CheckingActivities = () => {
  return (
    <div className={styles.checking_activities}>
      <Spinner name="loadingCircle" size={48} />
    </div>
  );
};

const SendToPlaygroundModal = () => {
  const {
    isOpen,
    closeSendToPlaygroundModal,
    bobjects,
    typeMismatch,
    checkingActivities,
    checkedActivities,
    type,
  } = useSendToPlaygroundModal();

  const { createToast } = useToasts();

  const [testSetName, setTestSetName] = useState(undefined);

  const canSend =
    !checkingActivities && !typeMismatch && checkedActivities?.activitiesWithTranscript > 0;

  const onClose = () => {
    closeSendToPlaygroundModal();
    setTestSetName(undefined);
  };

  const handleConfirm = () => {
    api
      .post('/copilot/playground/test-set', {
        activities: bobjects.map(b => b.id.objectId),
        activityType: type,
        name: testSetName,
      })
      .then(testSet => {
        createToast({ type: 'success', message: 'Test set created!' });
        onClose();
      });
  };

  return (
    <Modal width={472} open={isOpen} onClose={onClose}>
      <ModalHeader size="small">
        <ModalTitle icon="playground" size="small">
          Send to playground
        </ModalTitle>
        <ModalCloseIcon size="small" onClick={onClose} />
      </ModalHeader>
      <ModalContent className={styles.content}>
        {checkingActivities && <CheckingActivities />}
        {!checkingActivities && typeMismatch && <TypeMismatch />}
        {!checkingActivities &&
          !typeMismatch &&
          checkedActivities &&
          checkedActivities.activitiesWithTranscript === 0 && <NoTranscriptActivities />}
        {canSend && checkedActivities.activitiesWithTranscript === bobjects.length && (
          <CorrectSet
            activitiesWithTranscript={checkedActivities.activitiesWithTranscript}
            type={type}
          />
        )}
        {canSend && checkedActivities.activitiesWithTranscript < bobjects.length && (
          <PartialSet
            activitiesWithTranscript={checkedActivities.activitiesWithTranscript}
            activitiesWithoutTranscript={checkedActivities.activitiesWithoutTranscript}
            type={type}
          />
        )}
        {canSend && (
          <Input
            className={styles.name_input}
            placeholder="Test set name"
            value={testSetName}
            onChange={setTestSetName}
            width="100%"
          />
        )}
      </ModalContent>
      <ModalFooter>
        <Button variant="clear" onClick={onClose}>
          {canSend ? 'Cancel' : 'Close'}
        </Button>
        {canSend && (
          <Button disabled={!testSetName} onClick={handleConfirm}>
            Confirm
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default SendToPlaygroundModal;
