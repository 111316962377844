import { useState, useMemo } from 'react';

import { ACTIVITY_FIELDS_LOGIC_ROLE, Bobject, BobjectId, BobjectTypes } from '@bloobirds-it/types';
import {
  api,
  getTextFromLogicRole,
  getValueFromLogicRole,
  injectReferencesGetProcess,
} from '@bloobirds-it/utils';
import useSWR from 'swr';

export interface ActivityInfo extends Bobject {
  botId: string;
  recordCall: string;
}

export interface Source {
  src: string;
  type: string;
}

const parseActivityForAnalysis = (bobject: Bobject): ActivityInfo => {
  const botId = getTextFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.BOT_ID);
  const recordCall = getValueFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.CALL_RECORD_URL);

  return {
    botId,
    recordCall,
    ...bobject,
  };
};

const useGetActivityAudio = (id: BobjectId<BobjectTypes.Activity>['value']) => {
  const { data, mutate, isLoading } = useSWR(id && 'activityAnalysis/' + id, () =>
    api.get(`/bobjects/${id}/form?injectReferences=true`),
  );

  const activityParsed = useMemo(() => {
    return data?.data && parseActivityForAnalysis(injectReferencesGetProcess(data?.data));
  }, [data]);

  return {
    activity: activityParsed,
    mutate,
    isLoading,
  };
};

export default useGetActivityAudio;
