import React, { useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  ColorType,
  Icon,
  Input,
  Item,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSection,
  ModalTitle,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Text,
  useToasts,
  Checkbox,
} from '@bloobirds-it/flamingo-ui';
import { useActiveUserSettings, useIsOTOAccount, useLanguage } from '@bloobirds-it/hooks';
import { PermissionType as SettingsPermissionType } from '@bloobirds-it/types';
import { mutate } from 'swr';

import { useUserSettingsReload } from '../../../../../components/userPermissions/hooks';
import { useEntity } from '../../../../../hooks';
import { forceSelectedEntitiesCacheRefresh } from '../../../../../hooks/entities/useEntity.utils';
import { useFullSalesEnabled } from '../../../../../hooks/useFeatureFlags';
import { isEmail } from '../../../../../misc/utils';
import { api } from '../../../../../utils/api';
import { randomizeColor } from '../../../../../utils/styles.utils';
import { USER_ROLES_BLACKLIST } from '../../constants/userForm.constants';
import { useCreateEditUserModal } from '../../hooks/useCreateEditUserModal';
import { useUserCreatedModal } from '../../hooks/useUserCreatedModal';
import styles from '../../styles/usersPage.module.css';
import { PermissionsModalSection } from './permissionsModalSection';
import { PermissionType, SectionInterface } from './types/permissions';

export interface PermissionField {
  fieldName: string;
  enumName: PermissionType;
  id: string;
}

export const CreateEditUserModal = () => {
  const { handleCloseUserModal, modalInfo } = useCreateEditUserModal();
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.salesTeam.createEditUserModal',
  });
  const userRoles = useEntity('userRoles');
  const userPermissionsFields = useEntity('userPermissions');
  const hasSalesEnabled = useFullSalesEnabled();
  const reloadUserSettings = useUserSettingsReload();
  const isOTOAccount = useIsOTOAccount();
  const { settings } = useActiveUserSettings();
  const generalSectionEnums: string[] = [
    'EDIT_ALL',
    'DOWNLOAD_LIST',
    'BULK_ACTIONS',
    'VIEW_CADENCES',
    'CUSTOM_TASK',
  ];
  const generalModalSections = useMemo(() => {
    const generalSections: SectionInterface[] = [];
    generalSectionEnums.forEach(section =>
      generalSections.push(
        userPermissionsFields?.find((field: { enumName: string }) => field?.enumName === section),
      ),
    );
    return generalSections;
  }, [userPermissionsFields]);
  const generalSectionsIds = generalModalSections?.map(section => section?.id);

  const visibilityModalSections = useMemo(() => {
    const visibilitySectionEnums = isOTOAccount
      ? ([
          'USER_ACTIVITY_VISIBILITY',
          'NURTURING_TAB',
          'INACTIVE_TAB',
          'PIPELINE_TAB',
          'CRM_LISTS_BULK_ACTIONS',
          'LINKEDIN',
          'SYNC_WHATSAPP_WEB_MESSAGES',
          'WHATSAPP_BUSINESS_ADMIN',
          'VIEW_CADENCES',
          'VIEW_DASHBOARDS_TAB',
        ] as const)
      : ([
          'USER_ACTIVITY_VISIBILITY',
          'NURTURING_TAB',
          'INACTIVE_TAB',
          'PIPELINE_TAB',
          'LINKEDIN',
          'SYNC_WHATSAPP_WEB_MESSAGES',
          'WHATSAPP_BUSINESS_ADMIN',
          'CRM_LISTS_BULK_ACTIONS',
          'VIEW_CADENCES',
          'VIEW_REPORTS',
          'VIEW_INBOUND_TAB',
          'VIEW_INBOX',
          'VIEW_OUTBOX_TAB',
          'VIEW_ASSIGN_TAB',
          'VIEW_PROSPECT_TAB',
          ...(hasSalesEnabled ? ['VIEW_SALES_TAB'] : []),
          'VIEW_DASHBOARDS_TAB',
          'VIEW_ADD_QC_TAB',
        ] as const);

    const visibilitySections: SectionInterface[] = [];

    visibilitySectionEnums.forEach(section =>
      visibilitySections.push(
        userPermissionsFields?.find((field: { enumName: string }) => field?.enumName === section),
      ),
    );
    return visibilitySections;
  }, [userPermissionsFields]);

  const visibilitySectionsIds = visibilityModalSections?.map(section => section?.id);
  const { handleOpenUserCreatedModal } = useUserCreatedModal();
  const employeeRoles = useEntity('employeeRoles');
  const isCreation = Object.keys(modalInfo).length === 0;
  const defaultOptionsChecked = visibilityModalSections.reduce((acc, section) => {
    if (['USER_ACTIVITY_VISIBILITY', 'VIEW_REPORTS'].includes(section?.enumName))
      acc.push(section.id);
    return acc;
  }, []);
  const { languages } = useLanguage();
  const { t: commonT } = useTranslation();

  const defaultValues = useMemo(
    () =>
      isCreation
        ? {
            assignable: true,
            editAll: true,
            generalPermissions: [],
            visibilityPermissions: defaultOptionsChecked,
            name: '',
            email: '',
            employeeRole: '',
            userRoles: '',
            language: settings?.account?.language,
            whatsappAutoSyncEnabled: false,
            selectSignatureEnabled: false,
            autoInsertSignatureEnabled: false,
          }
        : {
            ...modalInfo,
            ...{
              generalPermissions: modalInfo?.userPermissions,
              visibilityPermissions: modalInfo?.userPermissions,
            },
          },
    [generalSectionsIds, visibilitySectionsIds],
  );

  const methods = useForm({ defaultValues });

  const { createToast } = useToasts();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSave = (data: {
    name: string;
    generalPermissions: SectionInterface['enumName'][];
    visibilityPermissions: SectionInterface['enumName'][];
    employeeRole: any;
    assignable: any;
    userRoles: any;
    language: string;
    email: string;
    color: ColorType;
    whatsappAutoSyncEnabled: boolean;
    selectSignatureEnabled: boolean;
    autoInsertSignatureEnabled: boolean;
  }) => {
    setIsSubmitting(true);
    const shortNameLetters = data.name.replace(/[^a-zA-Z ]/g, ' ')?.split(' ');
    const shortName =
      shortNameLetters.length >= 2
        ? shortNameLetters[0].slice(0, 1).toUpperCase() +
          shortNameLetters[1].slice(0, 1).toUpperCase()
        : shortNameLetters[0].slice(0, 2).toUpperCase();
    const parsedPermissionsIds = [];
    if (data?.generalPermissions)
      parsedPermissionsIds.push(
        ...data.generalPermissions.filter(permission => generalSectionsIds.includes(permission)),
      );
    if (data?.visibilityPermissions)
      parsedPermissionsIds.push(
        ...data.visibilityPermissions.filter(permission =>
          visibilitySectionsIds.includes(permission),
        ),
      );

    const newData = {
      name: data?.name,
      employeeRole: data?.employeeRole,
      assignable: isOTOAccount ? true : data.assignable,
      userRoles: [data?.userRoles],
      language: data?.language,
      shortname: shortName,
      email: data?.email.trim().toLowerCase(),
      color: data?.color || randomizeColor(),
      userPermissions: parsedPermissionsIds,
      autoSyncWhatsappPermission: data?.whatsappAutoSyncEnabled
        ? SettingsPermissionType.FORCED
        : SettingsPermissionType.DISABLED,
      selectSignaturesPermission: data?.selectSignatureEnabled
        ? SettingsPermissionType.FORCED
        : SettingsPermissionType.DISABLED,
      autoInsertSignaturePermission: data?.autoInsertSignatureEnabled
        ? SettingsPermissionType.FORCED
        : SettingsPermissionType.DISABLED,
    };

    if (isCreation) {
      api
        .post('/utils/service/users/create', { ...newData })
        .then(() => {
          createToast({ message: t('toasts.success.create'), type: 'success' });
          mutate('/users').then(() => {
            forceSelectedEntitiesCacheRefresh(['bobjectPicklistFieldValues']);
          });
          setIsSubmitting(false);
          handleCloseUserModal();
          handleOpenUserCreatedModal();
        })
        .catch(() => {
          createToast({
            message: t('toasts.error.create'),
            type: 'error',
          });
          setIsSubmitting(false);
        });
    } else {
      api
        .patch(`/utils/service/users/${modalInfo.id}`, { ...newData })
        .then(() => {
          createToast({ message: t('toasts.success.edit'), type: 'success' });
          mutate('/users').then(() => {
            forceSelectedEntitiesCacheRefresh(['bobjectPicklistFieldValues']);
          });
          reloadUserSettings();
          setIsSubmitting(false);
          handleCloseUserModal();
        })
        .catch(() => {
          createToast({
            message: t('toasts.error.edit'),
            type: 'error',
          });
          setIsSubmitting(false);
        });
    }
  };

  return (
    <Modal open onClose={handleCloseUserModal} width={760}>
      <ModalHeader size="small">
        <div style={{ display: 'flex' }}>
          <Icon name="person" color="peanut" />
          <ModalTitle size="small">{t(`title.${isCreation ? 'create' : 'edit'}`)}</ModalTitle>
        </div>
        <ModalCloseIcon onClick={handleCloseUserModal} />
      </ModalHeader>
      <ModalContent>
        <FormProvider {...methods}>
          <ModalSection title={t('userInformation.title')} icon="person">
            <div className={styles._section}>
              <div className={styles._section__row}>
                <Controller
                  name="name"
                  rules={{
                    required: t('userInformation.required'),
                  }}
                  render={({ onChange, value }) => (
                    <Input
                      width="290"
                      name="name"
                      error={methods.errors.name?.message}
                      value={value}
                      onChange={onChange}
                      placeholder={t('userInformation.fullName')}
                    />
                  )}
                />
                <Controller
                  name="email"
                  rules={{
                    required: t('userInformation.required'),
                    validate: v => isEmail(v) || t('userInformation.emailFormat'),
                  }}
                  render={({ onChange, value }) => (
                    <Input
                      width="290"
                      name="email"
                      error={methods.errors.email?.message}
                      value={value}
                      onChange={onChange}
                      placeholder={t('userInformation.email')}
                    />
                  )}
                />
              </div>
              <div className={styles._section__row}>
                <Controller
                  name="userRoles"
                  rules={{
                    required: t('userInformation.required'),
                  }}
                  render={({ onChange, value }) => (
                    <Select
                      width="290"
                      onChange={onChange}
                      value={value}
                      placeholder={t('userInformation.userRole')}
                      error={methods.errors.userRoles?.message}
                    >
                      {userRoles
                        ?.all()
                        ?.filter(role => !USER_ROLES_BLACKLIST?.includes(role?.enumName))
                        ?.map(role => (
                          <Item key={role?.id} value={role?.id} data-test={role?.enumName}>
                            {role?.name}
                          </Item>
                        ))}
                    </Select>
                  )}
                />
                <Controller
                  name="employeeRole"
                  rules={{
                    required: t('userInformation.required'),
                  }}
                  render={({ onChange, value }) => (
                    <Select
                      width="290"
                      onChange={onChange}
                      error={methods.errors.employeeRole?.message}
                      value={value}
                      placeholder={t('userInformation.employeeRole')}
                    >
                      {employeeRoles?.all()?.map(role => (
                        <Item key={role?.id} value={role?.id} data-test={role?.name}>
                          {role?.name}
                        </Item>
                      ))}
                    </Select>
                  )}
                />
                <Controller
                  name="language"
                  rules={{
                    required: t('userInformation.required'),
                  }}
                  render={({ onChange, value }) => (
                    <Select
                      width="290"
                      onChange={onChange}
                      value={value}
                      placeholder={t('userInformation.language')}
                      error={methods.errors.language?.message}
                    >
                      {languages?.map(lang => (
                        <Item key={lang} value={lang} data-test={lang}>
                          {commonT(`languages.${lang}`)}
                        </Item>
                      ))}
                    </Select>
                  )}
                />
              </div>
            </div>
          </ModalSection>
          <ModalSection title={t('userPermissions.title')} icon="lock">
            <Controller
              name="generalPermissions"
              render={({ onChange, value }) => (
                <PermissionsModalSection
                  sections={generalModalSections}
                  sectionTitle={t('userPermissions.generalPermissions')}
                  values={value?.filter((val: string) => generalSectionsIds.includes(val))}
                  updateValue={onChange}
                />
              )}
            />
            <Controller
              name="visibilityPermissions"
              render={({ onChange, value }) => (
                <PermissionsModalSection
                  sections={visibilityModalSections}
                  sectionTitle={t('userPermissions.visibilityPermissions')}
                  selectAllHandle={!isOTOAccount && t('userPermissions.viewAll')}
                  values={value?.filter((val: string) => visibilitySectionsIds.includes(val))}
                  updateValue={onChange}
                  isVisibility
                />
              )}
            />
            <div className={styles._whatsapp_section}>
              <Text size="m" color="peanut" weight="bold">
                {t('userPermissions.whatsApp.title')}
              </Text>
              <Text size="s" color="peanut">
                {t('userPermissions.whatsApp.subtitle')}
              </Text>
              <Controller
                name="whatsappAutoSyncEnabled"
                render={({ onChange, value }) => (
                  <Checkbox checked={value} onClick={onChange} expand>
                    {t('userPermissions.whatsApp.checkbox')}
                  </Checkbox>
                )}
              />
            </div>

            <div className={styles._whatsapp_section}>
              <Text size="m" color="peanut" weight="bold">
                {t('userPermissions.signatures.title')}
              </Text>
              {/*               <Text size="s" color="peanut">
                Automatically sync all prospect&apos;s chat when accessing it from WhatsApp web as
                long as it is saved in the database
              </Text> */}
              <Controller
                name="selectSignatureEnabled"
                render={({ onChange, value }) => (
                  <Checkbox checked={value} onClick={onChange} expand>
                    {t('userPermissions.signatures.checkboxChange')}
                  </Checkbox>
                )}
              />
              <Controller
                name="autoInsertSignatureEnabled"
                render={({ onChange, value }) => (
                  <Checkbox checked={value} onClick={onChange} expand>
                    {t('userPermissions.signatures.checkboxInsert')}
                  </Checkbox>
                )}
              />
            </div>

            {!isOTOAccount && (
              <div className={styles._last_section}>
                <Text size="m" color="peanut" weight="bold">
                  {t('userPermissions.assign.title')}
                </Text>
                <Controller
                  name="assignable"
                  render={({ onChange, value }) => (
                    <RadioGroup onChange={onChange} value={value}>
                      <Radio value size="small">
                        <Text size="s" inline>
                          <Trans i18nKey="accountSettings.salesTeam.createEditUserModal.userPermissions.assign.visible" />
                        </Text>
                      </Radio>
                      <Radio value={false} size="small">
                        <Text size="s" inline>
                          <Trans i18nKey="accountSettings.salesTeam.createEditUserModal.userPermissions.assign.notVisible" />
                        </Text>
                      </Radio>
                    </RadioGroup>
                  )}
                />
              </div>
            )}
          </ModalSection>
        </FormProvider>
      </ModalContent>
      <ModalFooter>
        <div>
          <Button variant="clear" onClick={handleCloseUserModal}>
            {t('cancel')}
          </Button>
        </div>
        <div>
          <Button onClick={methods.handleSubmit(handleSave)} data-test="save-user-button">
            {isSubmitting ? <Spinner color="white" name="loadingCircle" size={16} /> : t('save')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
