import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTestRuns } from '@bloobirds-it/copilot';
import {
  Button,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalCloseIcon,
  Text,
  ModalContent,
  ModalFooter,
  Icon,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { CopilotInsight } from '@bloobirds-it/types';
import clsx from 'clsx';

import { useSetsData } from '../../hooks/useSetsData';
import styles from '../../playgroundPages.module.css';

export const NewRunModal = ({
  selectedVersion,
  onClose,
}: {
  selectedVersion: CopilotInsight;
  onClose: () => void;
}) => {
  const [selectedTestSet, setSelectedTestSet] = useState();
  const { createTestRun } = useTestRuns();
  const { createToast } = useToasts();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.newRunModal' });
  const { testSets } = useSetsData();

  async function onCreateTestRun() {
    createTestRun({
      testSetId: selectedTestSet?.pk,
      insightId: selectedVersion.id,
    })
      .then(() => {
        createToast({
          type: 'success',
          message: t('success'),
        });
      })
      .catch(() => {
        createToast({
          type: 'error',
          message: t('error'),
        });
      })
      .finally(() => {
        onClose();
      });
  }

  // Only show test sets that have the same activity type as the insight
  const filteredTestSets = testSets.filter(
    testSet =>
      testSet.activity_type === selectedVersion.activityType || testSet.activity_type === 'All',
  );

  return (
    <Modal open onClose={onClose} width={370}>
      <ModalHeader color="white" size="small">
        <ModalTitle size="small" icon="test" color="purple">
          <Text weight="bold">{t('title')}</Text>
        </ModalTitle>
        <ModalCloseIcon color="black" size="small" onClick={onClose} />
      </ModalHeader>
      <ModalContent className={styles.insight_createTestRun}>
        {filteredTestSets.map(testSet => {
          const { pk, name, activity_type, activities } = testSet;

          const classNamesTestSet = clsx(styles.testSets_testSet, {
            [styles.active]: selectedTestSet?.pk === pk,
          });

          return (
            <div key={pk} className={classNamesTestSet} onClick={() => setSelectedTestSet(testSet)}>
              <Text className={styles.testSets_testSetName} size="s" color="peanut" weight="heavy">
                {name}
              </Text>
              <div className={styles.testSets_testSetInfo}>
                {activity_type === 'Call' ? (
                  <Icon name="phone" size={16} color="melon" />
                ) : (
                  <Icon name="calendar" size={16} color="tomato" />
                )}
                <Text size="xs" color="peanut">
                  {Object.keys(activities).length || 0}{' '}
                  {activity_type === 'Call' ? 'Calls' : 'Meetings'}
                </Text>
              </div>
            </div>
          );
        })}
      </ModalContent>
      <ModalFooter className={styles.insight_createTestRunFooter}>
        <Button uppercase={false} size="small" color="purple" onClick={onCreateTestRun}>
          {t('createButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
