import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalCloseIcon,
  Text,
  ModalContent,
  ModalFooter,
  Select,
  Item,
  TextArea,
  Switch,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { CopilotInsight } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import { motion } from 'framer-motion';
import { mutate } from 'swr';

import styles from '../../playgroundPages.module.css';

export const NewVersionModal = ({
  selectedVersion,
  history,
  compareVersions = true,
  onClose,
}: {
  selectedVersion: CopilotInsight;
  history: CopilotInsight[];
  compareVersions?: boolean;
  onClose: () => void;
}) => {
  const [selectedVersionToCompare, setSelectedVersionToCompare] = useState(selectedVersion);
  const [compareWithPreviousVersion, setCompareWithPreviousVersion] = useState(compareVersions);
  const [editedPrompt, setEditedPrompt] = useState(selectedVersionToCompare?.prompt);
  const { createToast } = useToasts();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.newVersionModal' });
  const [isLaunchEffect, setLaunchEffect] = useState(false);

  function onSave() {
    api
      .patch(
        '/utils/service/copilot-insights/prompt/' + selectedVersionToCompare.versionIdentifier,
        { prompt: editedPrompt },
      )
      .then(() => {
        createToast({ type: 'success', message: t('success') });
        mutate('api/insights');
        mutate(`/api/insight/version/${selectedVersionToCompare.versionIdentifier}`);
        onClose();
      })
      .catch(() => {
        createToast({ type: 'error', message: t('error') });
      });
  }

  return (
    <Modal open onClose={onClose} width={1000}>
      <ModalHeader color="white" size="small">
        <ModalTitle size="small" icon="addCircle" color="purple">
          <Text weight="bold">{t('title')}</Text>
        </ModalTitle>
        <ModalCloseIcon color="black" size="small" onClick={onClose} />
      </ModalHeader>
      <ModalContent className={styles.insight_createVersion_content}>
        <div className={styles.insight_createVersion_content_title}>
          <Text size="s" color="softPeanut">
            <b>Prompt</b> Version: {history[0].version + 1}
          </Text>
          <div>
            <motion.div
              initial={!isLaunchEffect ? {} : { width: '0px', opacity: 0 }}
              animate={
                !isLaunchEffect
                  ? {}
                  : {
                      width:
                        compareWithPreviousVersion && selectedVersionToCompare ? '182px' : '0px',
                      opacity: compareWithPreviousVersion && selectedVersionToCompare ? 1 : 0,
                    }
              }
              transition={{ duration: 0.5 }}
            >
              <Select
                width="182px"
                size="small"
                placeholder="Select version to compare"
                value={selectedVersionToCompare?.version}
                onChange={value =>
                  setSelectedVersionToCompare(history.find(version => version.version === value))
                }
              >
                {history.map(version => (
                  <Item key={version.id} value={version.version}>
                    Version {version.version}
                  </Item>
                ))}
              </Select>
            </motion.div>
            <Text size="s" color="softPeanut">
              {t('compareVersions')}
            </Text>
            <Switch
              checked={compareWithPreviousVersion}
              color="purple"
              size="small"
              onChange={() => {
                setCompareWithPreviousVersion(!compareWithPreviousVersion);
                setLaunchEffect(true);
              }}
            />
          </div>
        </div>
        <div className={styles.insight_createVersion_content_prompt}>
          <motion.div
            style={{ overflow: 'hidden', height: '100%' }}
            initial={{
              width: compareWithPreviousVersion && selectedVersionToCompare ? '50%' : '100%',
            }}
            animate={
              !isLaunchEffect
                ? {}
                : {
                    width: compareWithPreviousVersion && selectedVersionToCompare ? '50%' : '100%',
                  }
            }
            transition={{ duration: 0.5 }}
          >
            <TextArea
              width="100%"
              value={editedPrompt}
              onChange={value => setEditedPrompt(value)}
            />
          </motion.div>
          <motion.div
            initial={{
              width: compareWithPreviousVersion && selectedVersionToCompare ? '50%' : '0%',
              opacity: compareWithPreviousVersion && selectedVersionToCompare ? 1 : 0,
            }}
            animate={
              !isLaunchEffect
                ? {}
                : {
                    width: compareWithPreviousVersion && selectedVersionToCompare ? '50%' : '0%',
                    opacity: compareWithPreviousVersion && selectedVersionToCompare ? 1 : 0,
                  }
            }
            transition={{ duration: 0.5 }}
          >
            <div>
              {selectedVersionToCompare?.prompt.split('\n').map((line, index) => (
                <Text size={line ? 'm' : 'xs'} key={index}>
                  {line}
                  <br />
                </Text>
              ))}
            </div>
          </motion.div>
        </div>
      </ModalContent>
      <ModalFooter className={styles.insight_createVersionFooter}>
        <Button color="purple" iconLeft="save" onClick={onSave}>
          {t('saveButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
