import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { IconButton, Text, Skeleton, Icon } from '@bloobirds-it/flamingo-ui';
import { useUserSettings } from '@bloobirds-it/hooks';
import { ActivityInsights, ActivityTranscript, TranscriptFragment } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

import styles from '../../../playgroundPages.module.css';
import SoundWave from './soundWave';
import useGetActivityAudio from './useGetActivityAudio';
import { InsightType } from './utils';

const ConversationBlock = ({ fragment }: { fragment: TranscriptFragment }) => {
  return (
    <div>
      <div className={styles.fragmentHeader}>
        <Text color="purple" size="xs" weight="bold">
          {/* @ts-ignore */}
          <Icon name={fragment.isLead ? 'person' : 'user'} color="purple" size={16} />
          {fragment.speaker} ({fragment.isLead ? 'lead' : 'user'})
        </Text>
      </div>

      <Text size="xxs">
        {fragment.text}
        <br />
      </Text>
    </div>
  );
};

const Label = ({
  active = true,
  children,
  onClick,
}: {
  active?: boolean;
  children: string;
  onClick?: () => void;
}) => {
  return (
    <div
      style={{
        background: !active ? '#E5ECF5' : '#464F57',
        color: !active ? '#94A5BA' : 'white',
      }}
      className={styles.testRun_activities_expanded_label}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const ExpandedActivityRow = ({ selectedTestSet, status, insightType, activityId, results }) => {
  const history = useHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.activityTab' });
  const [mode, setMode] = useState({
    audio: false,
    summary: status === 'COMPLETED' && insightType === InsightType.GENERATION,
    transcript: status === 'NOT_STARTED' || insightType === InsightType.DECISION,
  });
  const settings = useUserSettings();
  const accountId = settings?.account?.id;

  const { activity } = useGetActivityAudio(`${accountId}/Activity/${activityId}`);

  const { data: transcript, isLoading } = useSWR<ActivityTranscript>(
    activityId && `/copilot/transcript/call/${activityId}`,
    key => api.get<ActivityTranscript>(key).then(res => res.data),
  );

  const { data: insights, isLoading: isLoadingInsights } = useSWR<ActivityInsights>(
    activityId && status === 'COMPLETED' && `/copilot/transcript/insights/${activityId}`,
    key => api.get<ActivityInsights>(key).then(res => res.data),
  );

  return (
    <tr className={styles.testRun_activities_expanded_container}>
      <td className={styles.testRun_activities_expanded}>
        {status === 'NOT_STARTED' || insightType === InsightType.DECISION ? (
          <>
            <Label>{t('summary')}</Label>
            <div className={styles.blocks}>
              {status === 'NOT_STARTED' ? (
                <Text size="xs">
                  {t('summaryInfo', { activityType: selectedTestSet.activity_type })}
                </Text>
              ) : isLoadingInsights ? (
                Array.from({ length: 7 }).map((_, index) => (
                  <Skeleton key={index} variant="text" width="100%" height={30} />
                ))
              ) : (
                <Text size="xs">{insights?.summary}</Text>
              )}
            </div>
          </>
        ) : (
          <Text size="xs">{results}</Text>
        )}
      </td>
      <td className={styles.testRun_activities_expanded}>
        <div className={styles.testRun_activities_expanded_title}>
          {status === 'COMPLETED' && insightType === InsightType.GENERATION && (
            <Label
              active={mode.summary}
              onClick={() => setMode({ audio: false, summary: true, transcript: false })}
            >
              {t('summary')}
            </Label>
          )}
          <Label
            active={mode.transcript}
            onClick={() => setMode({ audio: false, summary: false, transcript: true })}
          >
            {t('transcript')}
          </Label>
          {activity?.recordCall && (
            <Label
              active={mode.audio}
              onClick={() => {
                setMode({ audio: true, summary: false, transcript: false });
              }}
            >
              Audio
            </Label>
          )}
          {activity?.botId && (
            <IconButton
              name="externalLink"
              size={16}
              color="softPurple"
              onClick={() => {
                const nuevaRuta = history.createHref({
                  pathname: `/app/ai-analysis/meeting/${activityId}`,
                });
                window.open(nuevaRuta, '_blank');
              }}
            />
          )}
        </div>
        <div className={styles.blocks}>
          {mode.summary &&
            (isLoadingInsights ? (
              Array.from({ length: 7 }).map((_, index) => (
                <Skeleton key={index} variant="text" width="100%" height={30} />
              ))
            ) : (
              <Text size="xs">{insights?.summary}</Text>
            ))}
          {mode.transcript &&
            (isLoading
              ? Array.from({ length: 7 }).map((_, index) => (
                  <Skeleton key={index} variant="text" width="100%" height={30} />
                ))
              : transcript?.transcript?.map((fragment, index) => (
                  <ConversationBlock key={index} fragment={fragment} />
                )))}
          {mode.audio && <SoundWave id={activityId} activityAudio={activity} />}
        </div>
      </td>
    </tr>
  );
};

export default ExpandedActivityRow;
