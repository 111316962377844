import { IconType, ColorType } from '@bloobirds-it/flamingo-ui';
import { InsightStatus } from '@bloobirds-it/types';

export const INSIGHT_STATUS_ICON: Record<InsightStatus, IconType | null> = {
  GENERATED: null,
  GENERATING: 'clock',
  FAILED: 'alertTriangle',
  NOT_PROCESSED: 'infoFilled',
  NOT_GENERATED: 'infoFilled',
  NO_RESULTS: 'searchNone',
};

export const INSIGHT_STATUS_BG_COLOR: Record<InsightStatus, ColorType | null> = {
  GENERATED: null,
  GENERATING: 'verySoftPeanut',
  FAILED: 'verySoftTomato',
  NOT_PROCESSED: 'lightBloobirds',
  NO_RESULTS: 'verySoftPeanut',
  NOT_GENERATED: 'lightBloobirds',
};
export const INSIGHT_STATUS_COLOR: Record<InsightStatus, ColorType | null> = {
  GENERATED: null,
  GENERATING: 'softPeanut',
  FAILED: 'softTomato',
  NOT_PROCESSED: 'bloobirds',
  NOT_GENERATED: 'bloobirds',
  NO_RESULTS: 'softPeanut',
};
export const INSIGHT_STATUS_LABEL: Record<InsightStatus, string | null> = {
  GENERATED: null,
  GENERATING: 'generating',
  FAILED: 'failed',
  NOT_PROCESSED: 'notProcessed',
  NO_RESULTS: 'noResults',
  NOT_GENERATED: 'notGenerated',
};
export const INSIGHT_STATUS_LABEL_COLOR: Record<InsightStatus, ColorType | null> = {
  GENERATED: null,
  GENERATING: 'peanut',
  FAILED: 'tomato',
  NOT_PROCESSED: 'bloobirds',
  NO_RESULTS: 'peanut',
  NOT_GENERATED: 'bloobirds',
};
