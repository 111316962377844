import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dropdown,
  Icon,
  Label,
  Text,
  useToasts,
  useVisible,
} from '@bloobirds-it/flamingo-ui';
import {
  CopilotInsight,
  InsightType,
  INSIGHT_STATE_OPTIONS,
  INSIGHT_TYPE_OPTIONS,
  ACTIVITY_TYPE_OPTIONS,
  InsightState,
} from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import { mutate } from 'swr';

import styles from '../../playgroundPages.module.css';
import { NewVersionModal } from './newVersionModal';

const InsightStateDropdown = ({ id, state }: { id: string; state: string }) => {
  const { ref, visible, setVisible } = useVisible(false);
  const [insightState, setInsightState] = useState(state);
  const { t: tBase } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.insightCard' });
  const { createToast } = useToasts();

  const onSelectStateChange = (value: InsightState) => {
    api
      .patch('/utils/service/copilot-insights/changeState/' + id, {
        newState: value,
      })
      .then(() => {
        setInsightState(value);
        createToast({
          message: t('changeStateSuccess'),
          type: 'success',
        });
        mutate('api/insights');
      })
      .catch(() => {
        createToast({
          message: t('changeStateError'),
          type: 'error',
        });
      })
      .finally(() => {
        setVisible(false);
      });
  };

  const stateActive = INSIGHT_STATE_OPTIONS(tBase).find(option => option.id === insightState);

  return (
    <Dropdown
      ref={ref}
      visible={visible}
      position="bottom"
      onClose={() => setVisible(false)}
      arrow={false}
      anchor={
        stateActive && (
          <Label
            size="small"
            color={stateActive?.color}
            textColor={stateActive?.textColor}
            icon={stateActive?.icon}
            iconColor={stateActive?.iconColor}
            iconSize={14}
            onClick={() => setVisible(!visible)}
            uppercase={false}
          >
            {stateActive?.name}
          </Label>
        )
      }
    >
      <div className={styles.insight_dropdown}>
        {INSIGHT_STATE_OPTIONS(tBase).map(state => (
          <Label
            key={state.id}
            size="small"
            color={state.color}
            textColor={state.textColor}
            icon={state.icon}
            iconColor={state.iconColor}
            iconSize={14}
            onClick={() => onSelectStateChange(state.id)}
            uppercase={false}
          >
            {state.name}
          </Label>
        ))}
      </div>
    </Dropdown>
  );
};

export const InsightHeader = ({
  selectedInsight,
  history,
}: {
  selectedInsight: CopilotInsight;
  history: CopilotInsight[];
}) => {
  const [openNewVersionModal, setOpenNewVersionModal] = useState(false);
  const { t: tBase } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.insightCard' });

  const onOpenNewVersion = () => {
    setOpenNewVersionModal(true);
  };

  /*   const onEdit = () => {
    console.log('clicked');
  }; */

  const { title, state, insightType, activityType, activitySubtype } = selectedInsight;
  const currentActivityType = ACTIVITY_TYPE_OPTIONS(tBase).find(
    option => option.id === activityType,
  );
  const currentType = INSIGHT_TYPE_OPTIONS(tBase).find(option => option.id === insightType);
  const activeVersion = history?.find((version: any) => version.isActiveVersion);

  return (
    <>
      <div className={styles.insight_header}>
        <div className={styles.insight_title}>
          <Text size="xl" color="peanut" weight="bold">
            {title}
          </Text>
          <div>
            <InsightStateDropdown id={selectedInsight.id} state={state} />
            <Button
              uppercase={false}
              variant="secondary"
              size="small"
              onClick={onOpenNewVersion}
              iconLeft="plus"
              color="peanut"
            >
              {t('newVersion')}
            </Button>
            {/* <IconButton name="edit" color="peanut" onClick={onEdit} /> */}
          </div>
        </div>
        <Text size="xs" color="peanut">
          {t('description')}
        </Text>
        <div className={styles.insight_info}>
          <div>
            <Icon name="gridSquares" size={14} color="softPeanut" />
            <Text size="s" weight="bold">
              {t('type')}:
            </Text>
            <Label
              size="small"
              color="lightPeanut"
              textColor="peanut"
              icon={insightType === InsightType.GENERATION ? 'suggestions' : 'book'}
              iconColor="peanut"
              iconSize={14}
              overrideStyle={{ display: 'flex' }}
              uppercase={false}
            >
              {currentType?.name}
            </Label>
          </div>
          <div>
            <Icon name="taskAction" size={14} color="softPeanut" />
            <Text size="s" weight="bold">
              {t('activityType')}:
            </Text>
            <Text size="s">{currentActivityType?.name}</Text>
          </div>
        </div>
        <div className={styles.insight_info}>
          <div>
            <Icon name="number" size={14} color="softPeanut" />
            <Text size="s" weight="bold">
              {t('activeVersion')}:
            </Text>
            <Text size="s">Version {activeVersion?.version ?? '0'}</Text>
          </div>
          <div>
            <Icon name="child" size={14} color="softPeanut" />
            <Text size="s" weight="bold">
              {t('activitySubtype')}:
            </Text>
            <Text size="s">{activitySubtype || 'All'}</Text>
          </div>
        </div>
      </div>
      {openNewVersionModal && (
        <NewVersionModal
          selectedVersion={activeVersion}
          history={history}
          compareVersions={false}
          onClose={() => setOpenNewVersionModal(false)}
        />
      )}
    </>
  );
};
