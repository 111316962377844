import React from 'react';

import { Skeleton } from '@bloobirds-it/flamingo-ui';

import styles from '../../playgroundPages.module.css';

const TestSetSkeleton = () => {
  return (
    <div className={styles.testSets_testSet}>
      <div className={styles.testSets_skeleton}>
        <Skeleton variant="rect" width="100%" height={40} />
        <Skeleton variant="rect" width="100%" height={16} />
      </div>
    </div>
  );
};

export default TestSetSkeleton;
