import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Label, Text } from '@bloobirds-it/flamingo-ui';
import { INSIGHT_TYPE_OPTIONS, INSIGHT_STATE_OPTIONS } from '@bloobirds-it/types';
import clsx from 'clsx';

import styles from '../../playgroundPages.module.css';

const InsightCard = ({
  insight,
  isSelected,
  handleClick,
}: {
  insight: any;
  isSelected: boolean;
  handleClick: () => void;
}) => {
  const { t: tBase } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.insightCard' });

  const { id, title, state, insightType, version, activityType, activitySubtype } = insight;

  const classes = clsx(styles.insight_card, {
    [styles.active]: isSelected,
  });

  const stateActive = INSIGHT_STATE_OPTIONS(tBase).find(option => option.id === state);
  const typeActive = INSIGHT_TYPE_OPTIONS(tBase).find(option => option.id === insightType);

  return (
    <div key={id} className={classes} onClick={handleClick}>
      <div className={styles.insight_card_row}>
        <Text size="s" weight="heavy">
          {title}
        </Text>
      </div>
      <div className={styles.insight_card_row}>
        <div>
          <Icon name="stars" size={14} color="softPeanut" />
          <Text size="s" weight="bold">
            {t('type')}:
          </Text>
          <Label
            size="small"
            color={typeActive.color}
            textColor={typeActive.textColor}
            icon={typeActive.icon}
            iconColor={typeActive.iconColor}
            iconSize={14}
            uppercase={false}
          >
            {typeActive.name}
          </Label>
        </div>
        <div>
          <Icon name="activity" size={14} color="softPeanut" />
          <Text size="s" weight="bold">
            {t('status')}:
          </Text>
          {stateActive && (
            <Label
              size="small"
              color={stateActive.color}
              textColor={stateActive.textColor}
              icon={stateActive.icon}
              iconColor={stateActive.iconColor}
              iconSize={14}
              uppercase={false}
            >
              {stateActive.name}
            </Label>
          )}
        </div>
      </div>
      <div className={styles.insight_card_row}>
        <div>
          <Icon name="number" size={14} color="softPeanut" />
          <Text size="s" weight="bold">
            {t('nVersions')}:
          </Text>
          <Text size="s" color="peanut">
            {version + 1}
          </Text>
        </div>
        <div>
          <Icon name="gridSquares" size={14} color="softPeanut" />
          <Text size="s" weight="bold">
            {t('activityType')}:
          </Text>
          <Text size="s" color="peanut">
            {activityType} {activitySubtype ? `- ${activitySubtype}` : ''}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default InsightCard;
