import { IconType } from '@bloobirds-it/flamingo-ui';
import {
  Bobject,
  BobjectTypes,
  EditableTemplateType,
  ExtensionLead,
  MainBobjectTypes,
  MessagingTemplate,
  PlaybookTab,
  TemplateStage,
} from '@bloobirds-it/types';

import { Environment } from '../../types/playbook';
import { SegmentationFilters } from '../playbookFilters/segmentationFilter';
import { VisibilityFilters } from '../playbookFilters/useSegmentationFilter';

export enum TemplatesBlock {
  MyTemplates = 'myTemplates',
  TeamTemplates = 'teamTemplates',
  SuggestedTemplates = 'suggestedTemplates',
  MySnippets = 'mySnippets',
}

interface PlaybookSection {
  icon: IconType;
  titleKey: string;
}

export type PlaybookSections = Record<TemplatesBlock, PlaybookSection>;

export interface TemplateListProps {
  messagingTemplates: MessagingTemplate[];
  shouldShowTooltip: boolean;
  handleAddTemplateClick: () => void;
  renderTemplate: (template: MessagingTemplate, showSnippetsTooltip: boolean) => JSX.Element;
  paginationProps: {
    page: number;
    hasNextPage: boolean;
    rowsPerPage: number;
    onChangePage: (page: number) => void;
    onChangeRowsPerPage: (size: number) => void;
  };
  templatesBlock: TemplatesBlock;
  noTemplatesProps: {
    isLoading: boolean;
    noResults: boolean;
    noTemplates: boolean;
  };
}

export interface PlaybookFeedProps {
  shouldShowTemplateSuggestions: boolean;
  accountId: string;
  mainBobject?: Bobject<MainBobjectTypes>;
  activeBobject: Bobject<MainBobjectTypes> | ExtensionLead;
  isMainBobjectSalesStage?: boolean;
  isFilterViewOpen?: boolean;
  stage: TemplateStage;
  company?: Bobject<BobjectTypes.Company>;
  leads?: Bobject[];
  opportunities?: Bobject[];
  selectedTab?: PlaybookTab;
  setSelectedTab?: (tab: PlaybookTab) => void;
  isSmartEmail?: boolean;
  isDialer?: boolean;
  onCardClicked: (
    template: MessagingTemplate,
    tabSelected?: string,
    handleEmailModal?: (template: MessagingTemplate) => void,
  ) => void;
  refreshMainBobject?: (bobjectType: string) => void;
  toggleFilterView: (value: boolean, selectedTab: PlaybookTab) => void;
  setFiltersContext?: (values: SegmentationFilters) => void;
  segmentationValues: any;
  visibilityFilters?: VisibilityFilters;
  templateFunctions: Record<string, (template: EditableTemplateType) => void>;
  actionsDisabled?: boolean;
  segmentationFields: any;
  sidePeekEnabled?: boolean;
  environment: Environment;
  whatsappData?: {
    phoneNumber: string;
    isSameActiveLead: boolean;
    userName: string;
    lead: ExtensionLead;
  };
  headerComponent: JSX.Element | Element;
}
