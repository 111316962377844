import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, Text } from '@bloobirds-it/flamingo-ui';
import { CopilotInsight, TestRun } from '@bloobirds-it/types';

import useResults from '../../hooks/useResults';
import styles from '../../playgroundPages.module.css';

const ResultsCard = ({
  testRun,
  isVisible,
  children,
  insights = [],
}: {
  testRun: TestRun;
  isVisible: boolean;
  children: React.ReactNode;
  insights: CopilotInsight[];
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.runs' });

  const { testSuccessRate, testWarningRate, testErrorRate, testWithoutExpectedRate } = useResults(
    testRun,
  );

  if (!testRun.summarized_results || testRun.status === 'NOT_STARTED') {
    return <>{children}</>;
  }
  const testRunInsight = insights.find(insight => insight.id === testRun.insight_version_id);

  return (
    <Dropdown
      visible={isVisible}
      width={200}
      position="bottom"
      arrow={false}
      style={{
        padding: '0px',
      }}
      anchor={children}
    >
      <div className={styles.results}>
        <Text size="s" color="softPeanut">
          {t('currentRun')}
        </Text>
        <div className={styles.results_row}>
          <span className={styles.results_success} />
          <Text size="xs">{t('perfectMatch')}</Text>
          <Text size="xs" weight="heavy">
            {testSuccessRate}%
          </Text>
        </div>
        {testRunInsight?.insightType !== 'GENERATION' && (
          <div className={styles.results_row}>
            <span className={styles.results_warning} />
            <Text size="xs">{t('partialMatch')}</Text>
            <Text size="xs" weight="heavy">
              {testWarningRate}%
            </Text>
          </div>
        )}
        <div className={styles.results_row}>
          <span className={styles.results_error} />
          <Text size="xs">{t('mismatch')}</Text>
          <Text size="xs" weight="heavy">
            {testErrorRate}%
          </Text>
        </div>
        <div className={styles.results_row}>
          <span className={styles.results_without_expected} />
          <Text size="xs">{t('noReference')}</Text>
          <Text size="xs" weight="heavy">
            {testWithoutExpectedRate}%
          </Text>
        </div>
      </div>
    </Dropdown>
  );
};

export default ResultsCard;
