import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Text } from '@bloobirds-it/flamingo-ui';
import { CopilotInsight } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

import styles from '../../playgroundPages.module.css';
import { InsightHeader } from './insightHeader';
import { InsightTabs } from './insightTabs';

const InsightsInfo = ({ selectedInsight }: { selectedInsight: CopilotInsight }) => {
  const versionId = selectedInsight?.versionIdentifier;
  const { t } = useTranslation('translation', { keyPrefix: 'ai.insightList' });

  const { data, isLoading } = useSWR(versionId ? `/api/insight/version/${versionId}` : null, () =>
    api.get('/utils/service/copilot-insights/version/' + versionId),
  );

  // order by version from newest to oldest
  const history = data?.data.sort((a: any, b: any) => b.version - a.version);

  if (!selectedInsight) {
    return (
      <div className={styles.insight_empty}>
        <span>👈</span>
        <Text size="s" color="peanut" align="center">
          <Trans t={t} i18nKey="empty" />
        </Text>
        <Text size="xs" color="peanut" align="center">
          <Trans t={t} i18nKey="emptyDesc" />
        </Text>
      </div>
    );
  }

  return (
    <div className={styles.insight_container}>
      <InsightHeader history={history} selectedInsight={selectedInsight} />
      <InsightTabs history={history} isLoading={isLoading} />
    </div>
  );
};

export default InsightsInfo;
