import { useState } from 'react';

import { TestSet } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import { AxiosResponse } from 'axios';
import useSWR from 'swr';

export const useSetsData = () => {
  const [selectedTestSetId, setSelectedTestSetId] = useState<string>();

  const { data: testSets, isValidating } = useSWR<AxiosResponse<TestSet[]>>('/api/test-sets', () =>
    api.get('/copilot/playground/test-set'),
  );

  return {
    isLoading: isValidating,
    testSets: (testSets?.data || []).sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    ),
    selectedTestSetId,
    setSelectedTestSetId,
  };
};
