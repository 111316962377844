import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormField, TextAreaField } from '@bloobirds-it/bobjects';
import {
  Button,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Spinner,
  Text,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { CopilotInsight, ACTIVITY_TYPE_OPTIONS, INSIGHT_TYPE_OPTIONS } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import { mutate } from 'swr';

import { useActivityFieldsInfo } from '../hooks/useActivityFieldsInfo';
import styles from './insightFormModal.module.css';

interface IInsightFormModal {
  onClose: () => void;
  mode?: 'edit' | 'create';
  currentInsight?: CopilotInsight;
}
export const InsightFormModal = ({
  onClose,
  mode = 'create',
  currentInsight = new CopilotInsight({}),
}: IInsightFormModal) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ai.insightFormModal',
  });
  const { t: baseT } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const { createToast } = useToasts();
  const { meetingTypes = [], bobjectFields = [] } = useActivityFieldsInfo();
  const methods = useForm({ defaultValues: currentInsight.getInsightForCreation() });
  const [goNext, setGoNext] = useState(false);

  const defaultFormValues = {
    control: methods.control,
    hideLabel: true,
    visible: true,
    enabled: true,
    size: 'small' as const,
    withFormGroup: false,
  };
  const activityType = methods.watch('activityType');
  const subTypes = activityType === 'Meeting' ? meetingTypes : [];

  const onSubmit = (data: CopilotInsight) => {
    if (mode === 'create' && !goNext) {
      setGoNext(true);
      return;
    }

    setSubmitting(true);

    api
      .post('/utils/service/copilot-insights', data)
      .then(() => {
        createToast({
          message: t('createSuccess'),
          type: 'success',
        });
        mutate('api/insights');
      })
      .catch(() => {
        createToast({
          message: t('createError'),
          type: 'error',
        });
      })
      .finally(() => {
        setSubmitting(false);
        onClose();
      });
  };

  return (
    <FormProvider {...methods}>
      <Modal
        open
        onClose={!submitting ? onClose : null}
        width={400}
        className={styles.insightModal}
      >
        <ModalHeader color="white" size="small">
          <ModalTitle color="purple" icon={mode === 'create' ? 'plus' : 'edit'} size="small">
            <Text size="l" weight="bold">
              {t(mode === 'create' ? 'titleCreate' : 'titleEdit')}
            </Text>
          </ModalTitle>
          <ModalCloseIcon color="purple" size="small" onClick={onClose} />
        </ModalHeader>
        <ModalContent className={styles.contentContainer}>
          {!goNext && (
            <>
              <Text size="s" color="softPeanut">
                {t('generalDetails')}
              </Text>
              <div className={styles.formRow}>
                <Text size="s">{t('name.label')}*</Text>
                <FormField
                  type="TEXT"
                  id="title"
                  formKey="title"
                  name="title"
                  placeholder={t('name.placeholder')}
                  values={[]}
                  required
                  order={0}
                  {...defaultFormValues}
                />
              </div>
              <div className={styles.formRow}>
                <Text size="s">{t('insightType.label')}*</Text>
                <FormField
                  type="PICKLIST"
                  id="insightType"
                  formKey="insightType"
                  name="insightType"
                  placeholder={t('insightType.placeholder')}
                  values={INSIGHT_TYPE_OPTIONS(baseT)}
                  required
                  order={1}
                  {...defaultFormValues}
                />
              </div>
              <div className={styles.description}>
                <Text size="s">{t('description.label')}</Text>

                <TextAreaField
                  id="description"
                  formKey="description"
                  name="description"
                  placeholder={t('description.placeholder')}
                  rows={8}
                  order={2}
                  values={[]}
                  //@ts-ignore
                  maxLength={250}
                  maxLengthMessage={baseT('bobjects.bobjectForm.maxLengthMessage')}
                  width="100%"
                  {...defaultFormValues}
                />
              </div>
              <Text size="s" color="softPeanut">
                {t('insightDetails')}
              </Text>
              <div className={styles.formRow}>
                <Text size="s">{t('temperature.label')}</Text>
                <FormField
                  type="NUMBER"
                  id="temperature"
                  formKey="temperature"
                  name="temperature"
                  placeholder={t('temperature.placeholder')}
                  values={[]}
                  order={2}
                  {...defaultFormValues}
                />
              </div>
              <div className={styles.formRow}>
                <Text size="s">{t('activityField.label')}</Text>
                <FormField
                  type="PICKLIST"
                  id="activityField"
                  formKey="activityField"
                  name="activityField"
                  placeholder={t('activityField.placeholder')}
                  values={bobjectFields}
                  required
                  order={3}
                  {...defaultFormValues}
                />
              </div>
              <div className={styles.formRow}>
                <Text size="s">{t('activityType.label')}</Text>
                <FormField
                  type="PICKLIST"
                  id="activityType"
                  formKey="activityType"
                  name="activityType"
                  placeholder={t('activityType.placeholder')}
                  values={ACTIVITY_TYPE_OPTIONS(baseT)}
                  order={4}
                  {...defaultFormValues}
                />
              </div>
              <div className={styles.formRow}>
                <Text size="s">{t('activitySubtype.label')}</Text>
                <FormField
                  type="PICKLIST"
                  id="activitySubtype"
                  formKey="activitySubtype"
                  name="activitySubtype"
                  placeholder={t('activitySubtype.placeholder')}
                  values={subTypes}
                  order={5}
                  {...defaultFormValues}
                  enabled={!!activityType}
                />
              </div>
            </>
          )}
          {mode === 'create' && goNext && (
            <>
              <Text size="s" color="softPeanut">
                {t('prompt.label')}
              </Text>
              <TextAreaField
                id="prompt"
                formKey="prompt"
                name="prompt"
                placeholder={t('prompt.placeholder')}
                rows={20}
                order={1}
                values={[]}
                width="100%"
                {...defaultFormValues}
              />
            </>
          )}
        </ModalContent>
        <ModalFooter className={styles.footer}>
          <Button
            uppercase={false}
            onClick={methods.handleSubmit(onSubmit)}
            size="small"
            color="purple"
          >
            {!submitting &&
              t(mode === 'create' ? (goNext ? 'confirmCreate' : 'next') : 'confirmEdit')}
            {submitting && <Spinner name={'loadingCircle'} />}
          </Button>
        </ModalFooter>
      </Modal>
    </FormProvider>
  );
};
