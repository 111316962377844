import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Skeleton, Text, useToasts } from '@bloobirds-it/flamingo-ui';
import { CopilotInsight } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import { mutate } from 'swr';

import styles from '../../playgroundPages.module.css';
import { InsightCardHistory } from './insightCardHistory';
import { NewRunModal } from './newRunModal';
import { NewVersionModal } from './newVersionModal';

const InsightHistory = ({
  history,
  isLoading,
}: {
  history: CopilotInsight[];
  isLoading: boolean;
}) => {
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [createNewVersion, setCreateNewVersion] = useState(false);
  const [createNewTestRun, setCreateNewTestRun] = useState(false);
  const { createToast } = useToasts();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.insightCard' });

  useEffect(() => {
    if (history) {
      const activeVersion = history.find(version => version.isActiveVersion);
      setSelectedVersion(activeVersion);
    }
  }, [history]);

  const onSetAsActive = (id: string) => {
    api
      .get('/utils/service/copilot-insights/' + id + '/enable')
      .then(() => {
        mutate(`/api/insight/version/${selectedVersion.versionIdentifier}`);
        createToast({
          type: 'success',
          message: t('setActiveSuccess'),
        });
      })
      .catch(() => {
        createToast({
          type: 'error',
          message: t('setActiveError'),
        });
      });
  };

  return (
    <>
      <div className={styles.history}>
        <div className={styles.version}>
          {isLoading &&
            [...Array(3)].map((_, index) => (
              <Skeleton key={index} variant="rect" height={50} width="100%" />
            ))}
          {history?.map(version => {
            const isSelected = selectedVersion?.id === version.id;

            return (
              <InsightCardHistory
                key={version.id}
                version={version}
                isSelected={isSelected}
                setSelectedVersion={setSelectedVersion}
                onSetAsActive={onSetAsActive}
              />
            );
          })}
        </div>
        <div className={styles.version_prompt}>
          {(isLoading || !selectedVersion) && (
            <div className={styles.insight_empty}>
              <span>👈</span>
              <Text size="s" color="peanut" align="center">
                <Trans t={t} i18nKey="empty" />
              </Text>
              <Text size="xs" color="peanut" align="center">
                <Trans t={t} i18nKey="emptyDesc" />
              </Text>
            </div>
          )}
          {selectedVersion && (
            <>
              <div className={styles.version_icons}>
                <div className={styles.version_icons}>
                  <Button
                    uppercase={false}
                    size="small"
                    color="purple"
                    variant="tertiary"
                    iconLeft="fork"
                    onClick={() => setCreateNewVersion(true)}
                  >
                    {t('newVersion')}
                  </Button>
                  <Button
                    uppercase={false}
                    size="small"
                    color="purple"
                    variant="secondary"
                    iconLeft="pinOutline"
                    onClick={() => onSetAsActive(selectedVersion.id)}
                  >
                    {t('setActive')}
                  </Button>
                </div>
                <Button
                  uppercase={false}
                  size="small"
                  color="purple"
                  variant="primary"
                  iconLeft="deliver"
                  onClick={() => setCreateNewTestRun(true)}
                >
                  {t('createTestRun')}
                </Button>
              </div>

              <Text size="xs" color="peanut">
                {selectedVersion.prompt}
              </Text>
            </>
          )}
        </div>
      </div>
      {createNewVersion && (
        <NewVersionModal
          selectedVersion={selectedVersion}
          history={history}
          onClose={() => setCreateNewVersion(false)}
        />
      )}
      {createNewTestRun && (
        <NewRunModal selectedVersion={selectedVersion} onClose={() => setCreateNewTestRun(false)} />
      )}
    </>
  );
};

export default InsightHistory;
