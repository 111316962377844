import { useAccountId } from '@bloobirds-it/hooks';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

export const useActivityFieldsInfo = () => {
  const accountId = useAccountId();

  const key =
    '/entities/bobjectFields/search/findAllByAccountAndBobjectType_BobjectTypeModel_NameAndFieldType_NameIn';
  const {
    data: bobjectFields,
  } = useSWR(
    `${key}?bobjectTypeName=Activity&fieldTypeEnumName=Picklist,MultiPicklist&account=/accounts/${accountId}`,
    url => api.get(url).then(res => res.data._embedded.bobjectFields),
  );

  const meetingTypeField = bobjectFields?.find(
    bf => bf.logicRole === 'ACTIVITY__MEETING_MAIN_TYPE',
  );

  const keyMeetingTypes = `/entities/bobjectPicklistFieldValues/search/findAllByBobjectField?bobjectField=/bobjectFields/${meetingTypeField?.id}`;

  const { data: meetingTypes } = useSWR(meetingTypeField && `${keyMeetingTypes}`, url =>
    api.get(url).then(res => res.data._embedded.bobjectPicklistFieldValues),
  );

  return {
    bobjectFields,
    meetingTypes: (meetingTypes || []).map(mt => ({ id: mt.id, name: mt.value, enabled: true })),
  };
};
