import React from 'react';

import { IconButton, Text, useHover } from '@bloobirds-it/flamingo-ui';
import { CopilotInsight } from '@bloobirds-it/types';
import clsx from 'clsx';

import styles from '../../playgroundPages.module.css';

export const InsightCardHistory = ({
  version,
  isSelected,
  setSelectedVersion,
  onSetAsActive,
}: {
  version: CopilotInsight;
  isSelected: boolean;
  setSelectedVersion: (version: CopilotInsight) => void;
  onSetAsActive: (id: string) => void;
}) => {
  const [ref, isHovering] = useHover();

  const classes = clsx(styles.version_card, {
    [styles.active]: isSelected,
  });
  return (
    <div
      ref={ref}
      className={classes}
      onClick={() => {
        setSelectedVersion(version);
      }}
    >
      <div className={styles.version_card_title}>
        <Text size="s" weight="bold" color="peanut">
          Version {version.version}
        </Text>
        {(isSelected || isHovering) && (
          <IconButton
            name={version.isActiveVersion ? 'starChecked' : 'starUnchecked'}
            size={16}
            onClick={version.isActiveVersion ? null : () => onSetAsActive(version.id)}
            color="purple"
          />
        )}
      </div>
    </div>
  );
};
