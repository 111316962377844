import { useEffect, useRef, useState } from 'react';

import { useActiveAccountId, useCopilotEnabled, useInterval } from '@bloobirds-it/hooks';
import { CopilotInsight, SummarizedResult, TestRun, TestRunStatus } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

export type FilterType = {
  insight?: string | boolean;
  type?: string | boolean;
};

export type SortType = 'recent' | 'older' | 'better';

export const Sorts = {
  RECENT: 'recent',
  OLDER: 'older',
  BETTER: 'better',
};

export const useRunsData = (
  selectedTestSetId: string,
  insights: CopilotInsight[],
  selectedRunCallback: (selected: TestRun) => void,
) => {
  const [selectedRun, setSelectedRun] = useState<TestRun>();
  const [sort, setSort] = useState('recent');
  const [filter, setFilter] = useState({ insight: false, type: false });
  const [isPolling, setIsPolling] = useState(false);
  const executionCountRef = useRef(0);
  const MAX_EXECUTIONS = 5;

  const { data, mutate, isValidating } = useSWR(
    selectedTestSetId ? '/api/test-runs/' + selectedTestSetId : null,
    async () => {
      return await api.get<TestRun[]>(`/copilot/playground/test-set/${selectedTestSetId}/runs`);
    },
  );

  const accountId = useActiveAccountId();
  const isCopilotEnabled = useCopilotEnabled(accountId);

  const [
    isLoadingTestRunIntervalCleared,
    cancelLoadingTestRunInterval,
    resumeTranscriptInterval,
  ] = useInterval(
    () => {
      if (isCopilotEnabled && executionCountRef.current < MAX_EXECUTIONS) {
        mutate();
        executionCountRef.current += 1;
      }
    },
    5000,
    selectedTestSetId,
  );

  useEffect(() => {
    if (data?.data && data.data.some(testR => testR?.status === TestRunStatus.RUNNING)) {
      resumeTranscriptInterval();
      setIsPolling(true);
    } else {
      cancelLoadingTestRunInterval();
      setIsPolling(false);
    }
    if (data?.data && selectedRun) {
      const currentSelectedRun = data.data.find(run => run.pk === selectedRun.pk);
      if (currentSelectedRun) {
        setSelectedRun(currentSelectedRun);
        selectedRunCallback(currentSelectedRun);
      }
    }
  }, [data]);

  const refreshTestRuns = () => {
    executionCountRef.current = 0;
    resumeTranscriptInterval();
    setIsPolling(true);
  };

  const sortRuns = (runs: TestRun[], sort: SortType) => {
    if (!runs) {
      return [];
    }
    if (sort === Sorts.RECENT) {
      return runs?.sort(
        (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );
    }
    if (sort === Sorts.OLDER) {
      return runs?.sort(
        (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      );
    }
    if (sort === Sorts.BETTER) {
      return runs?.sort((a, b) => {
        const calculateScore = (result: SummarizedResult) => {
          if (!result) return 0;
          const {
            success = 0,
            partial_success = 0,
            failure = 0,
            processing_error = 0,
            total = 1,
          } = result;
          return (success * 4 + partial_success * 3 + failure * 2 + processing_error * 1) / total;
        };

        const scoreA = calculateScore(a.summarized_results);
        const scoreB = calculateScore(b.summarized_results);

        return scoreB - scoreA;
      });
    }
    return runs;
  };

  const filterRuns = (runs: TestRun[], filter: FilterType) => {
    if (!runs) {
      return [];
    }
    if (filter?.insight) {
      return runs.filter(run => run.insight_id === filter.insight);
    }
    if (filter?.type) {
      const insightsWithMatchingType = insights.filter(
        insight => insight.insightType === filter.type,
      );

      return runs.filter(run =>
        insightsWithMatchingType.some(insight => insight.versionIdentifier === run.insight_id),
      );
    }
    return runs;
  };

  return {
    isLoading: isValidating && !isPolling,
    testRuns: sortRuns(filterRuns(data?.data, filter), sort as SortType) || [],
    mutate,
    selectedRun,
    setSelectedRun,
    sort,
    setSort,
    filter,
    setFilter,
    refreshTestRuns,
  };
};
