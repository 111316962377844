import { ColorType, IconType } from '@bloobirds-it/flamingo-ui';
import { TFunction } from 'i18next';

export const INSIGHT_TYPE_OPTIONS = (
  t: TFunction,
): {
  id: InsightType;
  name: string;
  enabled: boolean;
  color: ColorType;
  textColor: ColorType;
  icon: IconType;
  iconColor: ColorType;
}[] => [
  {
    id: 'DECISION',
    name: t('ai.insightType.decision'),
    enabled: true,
    color: 'lightPeanut',
    textColor: 'peanut',
    icon: 'book',
    iconColor: 'peanut',
  },
  {
    id: 'EXTRACTION',
    name: t('ai.insightType.extraction'),
    enabled: true,
    color: 'lightPeanut',
    textColor: 'peanut',
    icon: 'externalLink',
    iconColor: 'peanut',
  },
  {
    id: 'GENERATION',
    name: t('ai.insightType.generation'),
    enabled: true,
    color: 'lightPeanut',
    textColor: 'peanut',
    icon: 'suggestions',
    iconColor: 'peanut',
  },
];
export const INSIGHT_STATE_OPTIONS = (
  t: TFunction,
): {
  id: InsightState;
  name: string;
  enabled: boolean;
  color: ColorType;
  textColor: ColorType;
  icon: IconType;
  iconColor: ColorType;
}[] => [
  {
    id: InsightState.ACTIVE,
    name: t('ai.insightState.active'),
    enabled: true,
    color: 'lightBloobirds',
    textColor: 'bloobirds',
    icon: 'play',
    iconColor: 'bloobirds',
  },
  {
    id: InsightState.CREATED,
    name: t('ai.insightState.created'),
    enabled: true,
    color: 'veryLightPeanut',
    textColor: 'peanut',
    icon: 'clock',
    iconColor: 'peanut',
  },
  {
    id: InsightState.DRAFT,
    name: t('ai.insightState.draft'),
    enabled: true,
    color: 'veryLightPeanut',
    textColor: 'softPeanut',
    icon: 'noteAction',
    iconColor: 'softPeanut',
  },
];
export const ACTIVITY_TYPE_OPTIONS = (
  t: TFunction,
): { id: string; name: string; enabled: boolean }[] => [
  { id: 'All', name: t('ai.insightActivity.All'), enabled: true },
  { id: 'Call', name: t('ai.insightActivity.Call'), enabled: true },
  { id: 'Meeting', name: t('ai.insightActivity.Meeting'), enabled: true },
];

export interface TranscriptFragment {
  speaker: string;
  start: number;
  end: number;
  text: string;
  isLead: boolean;
}

export type TranscriptStatus =
  | 'CANT_GENERATE'
  | 'NOT_GENERATED'
  | 'GENERATED'
  | 'GENERATING'
  | 'NOT_FINISHED'
  | 'ERROR';

export interface ActivityTranscript {
  accountId: string;
  transcript: TranscriptFragment[];
  activityId: string;
  status: TranscriptStatus;
  error: string;
}

export interface ActivityAnalysis {
  accountId: string;
  activityId: string;
  transcriptId: string;
  questions: string[];
  competitors: string[];
  summary: string;
  value_fit: {
    positive_points: string[];
    negative_points: string[];
  };
  status: TranscriptStatus;
}

export type InsightType = 'GENERATION' | 'EXTRACTION' | 'DECISION';
export const InsightType = {
  DECISION: 'DECISION' as InsightType,
  EXTRACTION: 'EXTRACTION' as InsightType,
  GENERATION: 'GENERATION' as InsightType,
};

export interface CoreInsightDefinition {
  id: string;
  accountId: string;
  insightType: InsightType;
  title: string;
  searchWords: string;
  activityField: string;
  versionIdentifier: string;
  version: string;
}

export interface PicklistField {
  name: string;
  values: { id: string; value: string }[];
  id: string;
  type: string;
}

export type InsightStatus =
  | 'GENERATED'
  | 'GENERATING'
  | 'FAILED'
  | 'NOT_PROCESSED'
  | 'NO_RESULTS'
  | 'NOT_GENERATED';

export interface Insight {
  pk: string;
  insightId: string;
  accountId: string;
  activityId: string;
  transcriptId: string;
  insight_type: InsightType;
  extracted_values?: string[];
  generated_text?: string;
  choices?: string[];
  status?: InsightStatus;
}

export interface ActivityInsights {
  accountId: string;
  activityId: string;
  transcriptId: string;
  insights: Insight[];
  summary: string;
  status: TranscriptStatus;
}

export interface CRMFieldUpdate {
  name: string;
  value: string;
  entity: string;
}

export interface CRMUpdates {
  accountId: string;
  activityId: string;
  transcriptId: string;
  updates: CRMFieldUpdate[];
  status: TranscriptStatus;
}

export type InsightState = 'ACTIVE' | 'CREATED' | 'DRAFT';
export const InsightState = {
  ACTIVE: 'ACTIVE' as InsightState,
  CREATED: 'CREATED' as InsightState,
  DRAFT: 'DRAFT' as InsightState,
};

export class CopilotInsight {
  id?: string = '';
  accountId?: string = '';
  insightType: InsightType;
  prompt: string;
  activityField: string | null;
  searchWords: any | null;
  temperature: number;
  title: string;
  version?: number = 0;
  versionIdentifier?: string = '';
  activityType: string;
  activitySubtype: string | null;
  state: InsightState;
  isActiveVersion: boolean;

  constructor(props: Partial<CopilotInsight>) {
    const {
      id = '',
      accountId = '',
      insightType = InsightType.GENERATION,
      prompt = '',
      activityField = '',
      searchWords = null,
      temperature = 0,
      title = '',
      version = 0,
      versionIdentifier = '',
      activityType = 'all',
      activitySubtype = '',
      state = 'DRAFT',
      isActiveVersion = false,
    } = props;

    this.id = id;
    this.accountId = accountId;
    this.insightType = insightType;
    this.prompt = prompt;
    this.activityField = activityField;
    this.searchWords = searchWords;
    this.temperature = temperature;
    this.title = title;
    this.version = version;
    this.versionIdentifier = versionIdentifier;
    this.activityType = activityType;
    this.activitySubtype = activitySubtype;
    this.state = state;
    this.isActiveVersion = isActiveVersion;
  }

  getInsightForCreation(): Omit<
    CopilotInsight,
    'id' | 'accountId' | 'version' | 'versionIdentifier'
  > {
    const { id, accountId, version, versionIdentifier, ...rest } = this;
    //@ts-ignore
    return rest;
  }
}

export interface SummarizedResult {
  success: number;
  partial_success: number;
  failure: number;
  processing_error: number;
  without_expected_result: number;
  total: number;
}

export enum TestRunStatus {
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  NOT_STARTED = 'NOT_STARTED',
}

export interface TestRun {
  pk: string;
  account_id: string;
  test_set_id: string;
  created_at: string;
  insight_version_id: string;
  insight_id: string;
  results: Record<string, any>;
  status: TestRunStatus;
  summarized_results: SummarizedResult;
  version_number: number;
}
type Activity = {
  [key: string]: {
    [key: string]:
      | {
          pk: string;
          account_id: string;
          activity_id: string;
          result: string;
          insight_id: string;
        }
      | object;
  };
};

export type TestSet = {
  pk: string;
  account_id: string;
  name: string;
  created_at: string;
  activity_type: string;
  activities: Activity;
};
