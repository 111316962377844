import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import { TestRunModal } from '@bloobirds-it/copilot';
import { Button, Dropdown, Icon, Item, IconButton, Text, Select } from '@bloobirds-it/flamingo-ui';
import { useTogglerState } from '@bloobirds-it/hooks';

import { useRunsData, Sorts } from '../../hooks/useRunsData';
import { usePlayground } from '../../playgroundPages';
import styles from '../../playgroundPages.module.css';
import TestRunsCardWrapper from './testRunsCard';
import TestRunsEmpty from './testRunsEmpty';
import TestRunsNoSetSelect from './testRunsNoSetSelect';
import TestRunSkeleton from './testRunsSkeleton';

const sortArray = [
  { value: Sorts.RECENT, label: <Trans i18nKey="ai.playground.runs.recentRunsFirst" /> },
  { value: Sorts.OLDER, label: <Trans i18nKey="ai.playground.runs.olderRunsFirst" /> },
  { value: Sorts.BETTER, label: <Trans i18nKey="ai.playground.runs.betterResultsFirst" /> },
];

export function TestRuns({ selectedTestSet, handleSelectedRun }) {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.runs' });
  const { t: baseT } = useTranslation();
  const [mode, setMode] = useState({ order: false, filter: false });
  const { insights } = usePlayground();
  const {
    isLoading,
    testRuns,
    mutate,
    selectedRun,
    setSelectedRun,
    sort,
    setSort,
    filter,
    setFilter,
    refreshTestRuns,
  } = useRunsData(selectedTestSet?.pk, insights, handleSelectedRun);
  const [openTestRunModal, toggle] = useTogglerState(false);

  const refOrderDropdown = React.useRef(null);
  useClickAway(refOrderDropdown, () => setMode(prevMode => ({ ...prevMode, order: false })));

  const onAddRun = () => {
    toggle();
  };
  let insightsForTheFilter = insights;
  if (filter.type) {
    insightsForTheFilter = insights.filter(ins => ins.insightType === filter.type);
  }
  return (
    <>
      <div className={styles.testRuns_container}>
        <div className={styles.test_header}>
          <div>
            <Icon name="repeat" size={20} color="softPeanut" />
            <Text size="s" color="softPeanut" weight="bold">
              {t('title')}
            </Text>
          </div>
          <div>
            <Dropdown
              ref={refOrderDropdown}
              visible={mode.order}
              width={150}
              position="bottom"
              style={{
                padding: '0px',
              }}
              anchor={
                <IconButton
                  disabled={isLoading || !selectedTestSet?.pk}
                  name="sort"
                  color="bloobirds"
                  onClick={() => setMode(prevMode => ({ ...prevMode, order: !prevMode.order }))}
                />
              }
            >
              <div className={styles.order}>
                {sortArray.map(({ value, label }) => (
                  <Item
                    key={value}
                    onClick={() => {
                      setSort(value);
                      setMode(prevMode => ({ ...prevMode, order: !prevMode.order }));
                    }}
                  >
                    <Text
                      size="s"
                      color={value === sort ? 'bloobirds' : 'softPeanut'}
                      weight="medium"
                    >
                      {label}
                    </Text>
                  </Item>
                ))}
              </div>
            </Dropdown>

            <IconButton
              disabled={isLoading || !selectedTestSet?.pk}
              name="sliders"
              color="bloobirds"
              onClick={() => setMode(prevMode => ({ ...prevMode, filter: !prevMode.filter }))}
            />
          </div>
        </div>
        {selectedTestSet?.pk && !isLoading && (
          <div className={styles.test_list}>
            {mode.filter && (
              <div className={styles.select}>
                <Select
                  size="small"
                  value={filter?.insight || ''}
                  onChange={value => setFilter(prevFilter => ({ ...prevFilter, insight: value }))}
                  placeholder={t('insightFilterPlaceholder')}
                >
                  <Item value="" key="all">
                    {baseT('leftBar.filters.all')}
                  </Item>
                  {insightsForTheFilter?.map(insight => (
                    <Item value={insight.versionIdentifier} key={insight.versionIdentifier}>
                      {insight?.title}
                    </Item>
                  ))}
                </Select>
                <Select
                  size="small"
                  value={filter?.type || ''}
                  onChange={value => setFilter(prevFilter => ({ ...prevFilter, type: value }))}
                  placeholder={t('insightActivityTypeFilterPlaceholder')}
                  disabled={filter?.insight}
                >
                  <Item value="" key="all">
                    {baseT('leftBar.filters.all')}
                  </Item>
                  {['GENERATION', 'EXTRACTION', 'DECISION'].map(insightsType => (
                    <Item value={insightsType} key={insightsType}>
                      {t(insightsType)}
                    </Item>
                  ))}
                </Select>
              </div>
            )}
            {testRuns?.length > 0 && (
              <Button
                expand
                className={styles.testRuns_createRun}
                uppercase={false}
                iconLeft="add"
                variant="primary"
                onClick={toggle}
                disabled={openTestRunModal || !selectedTestSet?.pk}
                color="lightPurple"
              >
                {t('createRun')}
              </Button>
            )}
            {testRuns.map(testRun => (
              <TestRunsCardWrapper
                key={testRun.pk}
                testRun={testRun}
                insights={insights}
                refreshTestRuns={refreshTestRuns}
                isSelected={testRun.pk === selectedRun?.pk}
                setSelectedRun={() => {
                  setSelectedRun(testRun);
                  handleSelectedRun(testRun);
                }}
                mutateTestRuns={mutate}
              />
            ))}
          </div>
        )}
        {!selectedTestSet?.pk && <TestRunsNoSetSelect />}
        {selectedTestSet?.pk && isLoading && (
          <div className={styles.test_list}>
            {[...Array(7)].map((_, index) => (
              <TestRunSkeleton key={index} />
            ))}
          </div>
        )}
        {selectedTestSet?.pk && !isLoading && testRuns?.length === 0 && (
          <TestRunsEmpty handleAddRun={onAddRun} />
        )}
      </div>
      {openTestRunModal && (
        <TestRunModal
          onClose={toggle}
          selectedTestSet={selectedTestSet}
          insights={insights}
          mutateTestRuns={mutate}
          testRuns={testRuns}
        />
      )}
    </>
  );
}
