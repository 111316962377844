import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@bloobirds-it/flamingo-ui';

import styles from '../../playgroundPages.module.css';

const TestRunsNoSetSelect = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.runs' });

  return (
    <div className={styles.test_empty}>
      <span>👈</span>
      <div>
        <Text size="s" color="peanut" weight="heavy" align="center">
          {t('noSetSelected')}
        </Text>
        <Text size="xs" color="peanut" align="center">
          {t('noSetSelectedDescription')}
        </Text>
      </div>
    </div>
  );
};

export default TestRunsNoSetSelect;
