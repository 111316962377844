import React from 'react';

import { Skeleton } from '@bloobirds-it/flamingo-ui';

import styles from '../../playgroundPages.module.css';

const TestRunSkeleton = () => {
  return (
    <div className={styles.testRuns_testRun_skeleton}>
      <Skeleton variant="rect" width="100%" height={80} />
    </div>
  );
};

export default TestRunSkeleton;
