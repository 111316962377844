import { BobjectId, BobjectTypes, LogicRoleType } from './bobjects';
import { TDateISO, TDateISODate } from './date';
import { OPPORTUNITY_STATUS_LOGIC_ROLE } from './opportunities';

export enum ACTIVITY_FIELDS_LOGIC_ROLE {
  ACCOUNT_EXECUTIVE = 'ACTIVITY__ACCOUNT_EXECUTIVE',
  AIRCALL_NOTE = 'ACTIVITY__AIRCALL_NOTE',
  ATTACHED_FILES = 'ACTIVITY__ATTACHED_FILES',
  CADENCE = 'ACTIVITY__CADENCE',
  CADENCE_STEP = 'ACTIVITY__CADENCE_STEP_ID',
  CADENCE_TITLE = 'ACTIVITY__CADENCE_TITLE',
  CADENCE_TYPE = 'ACTIVITY__TYPE_CADENCE',
  CALENDAR_NOTE = 'ACTIVITY__CALENDAR_NOTE',
  CALL_DURATION = 'ACTIVITY__CALL_DURATION',
  CALL_LEAD_PHONE_NUMBER = 'ACTIVITY__CALL_LEAD_PHONE_NUMBER',
  CALL_USER_PHONE_NUMBER = 'ACTIVITY__CALL_USER_PHONE_NUMBER',
  CALL_RECORD_URL = 'ACTIVITY__CALL_RECORD_URL',
  CALL_RESULT = 'ACTIVITY__CALL_RESULT',
  COMPANY = 'ACTIVITY__COMPANY',
  CREATE_EVENT = 'ACTIVITY__MEETING_CREATE_EVENT',
  CREATION_DATETIME = 'ACTIVITY__CREATION_DATETIME',
  DATE = 'ACTIVITY__DATE',
  DATETIME = 'ACTIVITY__TIME',
  DATA_SOURCE = 'ACTIVITY__DATA_SOURCE',
  DIRECTION = 'ACTIVITY__DIRECTION',
  EMAIL_HISTORY_CLICK = 'ACTIVITY__EMAIL_HISTORY_CLICK',
  EMAIL_HISTORY_OPEN = 'ACTIVITY__EMAIL_HISTORY_OPEN',
  EMAIL_LEAD = 'ACTIVITY__EMAIL_LEAD',
  EMAIL_USER = 'ACTIVITY__EMAIL_USER',
  EMAIL_METADATA = 'ACTIVITY__EMAIL_METADATA',
  EMAIL_THREAD_ID = 'ACTIVITY__EMAIL_THREAD_ID',
  EMAIL_TIMES_CLICKED = 'ACTIVITY__EMAIL_TIMES_CLICK',
  EMAIL_TIMES_OPENED = 'ACTIVITY__EMAIL_TIMES_OPEN',
  EMAIL_TIMES_REPLIED = 'ACTIVITY__EMAIL_TIMES_REPLY',
  INBOUND_FORM_NAME = 'ACTIVITY__INBOUND_FORM_NAME',
  IS_AUTO_EMAIL = 'ACTIVITY__IS_AUTOMATED_EMAIL',
  IS_AUTOMATED_EMAIL = 'ACTIVITY__IS_AUTOMATED_EMAIL',
  IS_ATTEMPT = 'ACTIVITY__IS_ATTEMPT',
  IS_BOUNCED_EMAIL = 'ACTIVITY__IS_BOUNCED_EMAIL',
  IS_PINNED = 'ACTIVITY__IS_PINNED',
  IS_TOUCH = 'ACTIVITY__IS_TOUCH',
  LEAD = 'ACTIVITY__LEAD',
  LINKEDIN_THREAD = 'ACTIVITY__LINKEDIN_THREAD',
  MAIN_NOTE = 'ACTIVITY__MAIN_NOTE',
  MEETING_ASSIGNED_TO = 'ACTIVITY__MEETING_ASSIGNED_TO',
  MEETING_INVITEES = 'ACTIVITY__MEETING_INVITEES',
  MESSAGE_BODY = 'ACTIVITY__MESSAGE_BODY',
  MEETING_DURATION = 'ACTIVITY__MEETING_DURATION',
  MEETING_MAIN_TYPE = 'ACTIVITY__MEETING_MAIN_TYPE',
  MEETING_RESULT = 'ACTIVITY__MEETING_RESULT',
  MESSAGE_SUBJECT = 'ACTIVITY__MESSAGE_SUBJECT',
  NOTE = 'ACTIVITY__NOTE',
  OPPORTUNITY = 'ACTIVITY__OPPORTUNITY',
  PITCH_DONE = 'ACTIVITY__PITCH_DONE',
  PITCH = 'ACTIVITY__PITCH',
  REMINDER_TEMPLATE = 'ACTIVITY__CALENDAR_REMINDER_TEMPLATE',
  REPORTED = 'ACTIVITY__REPORTED',
  SALESFORCE_CAMPAIGN_ID = 'SALESFORCE_CAMPAIGN_ID',
  SALESFORCE_CAMPAIGN_MEMBER_ID = 'SALESFORCE_CAMPAIGN_MEMBER_ID',
  SID = 'ACTIVITY__CALL_SID',
  SOURCE = 'ACTIVITY__DATA_SOURCE',
  STATUS_TITLE = 'ACTIVITY__STATUS_TITLE',
  UNIQUE_NYLAS_ID = 'ACTIVITY__EMAIL_UID',
  USER = 'ACTIVITY__USER',
  TABLE_VIEW_ITEM = 'ACTIVITY__TABLE_VIEW_ITEM',
  TIME = 'ACTIVITY__TIME',
  TITLE = 'ACTIVITY__MEETING_TITLE',
  TYPE = 'ACTIVITY__TYPE',
  CUSTOM_TASK = 'ACTIVITY__CUSTOM_TASK',
  ATTACHMENTS = 'ACTIVITY__ATTACHMENTS',
  CONFERENCING = 'ACTIVITY__MEETING_CONFERENCING',
  BOT_ID = 'ACTIVITY__MEETING_BOT_ID',
  WHATSAPP_ID = 'ACTIVITY__WHATSAPP_ID',
  CUSTOM_TASK_TYPE = 'ACTIVITY__CUSTOM_TASK_TYPE',
  COPILOT_ANALYSIS = 'ACTIVITY__COPILOT_ANALYSIS',
  TRANSCRIPTION = 'ACTIVITY__COPILOT_TRANSCRIPT',
  CASE_ID = 'ACTIVITY__CASE_ID',
  CASE_NUMBER = 'ACTIVITY__CASE_NUMBER',
  RELATED_OBJECT_ID = 'ACTIVITY__RELATED_OBJECT_ID',
}

export enum ACTIVITY_TYPES_VALUES_LOGIC_ROLE {
  // Be careful, cadence type is not the activity type, is the field Activity Type Cadence
  CADENCE = 'ACTIVITY__TYPE__CADENCE',
  CALL = 'ACTIVITY__TYPE__CALL',
  CHANGED_FROM = 'ACTIVITY__TYPE_STATUS_CHANGED_FROM',
  CHANGED_TO = 'ACTIVITY__TYPE_STATUS_CHANGED_TO',
  EMAIL = 'ACTIVITY__TYPE__EMAIL',
  INBOUND = 'ACTIVITY__TYPE__INBOUND',
  LINKEDIN = 'ACTIVITY__TYPE__LINKEDIN_MESSAGE',
  MEETING = 'ACTIVITY__TYPE__MEETING',
  NOTE = 'ACTIVITY__TYPE__NOTE',
  CUSTOM_TASK = 'ACTIVITY__TYPE__CUSTOM_TASK',
  // Be careful, cadence type is not the activity type, is the field Status Activity type
  STATUS = 'ACTIVITY__TYPE__STATUS',
}

export enum CALL_RESULTS_LOGIC_ROLE {
  CORRECT_CONTACT = 'ACTIVITY__CALL_RESULT__CORRECT_CONTACT',
  APPROACH = 'ACTIVITY__CALL_RESULT__APPROACH',
  GATEKEEPER = 'ACTIVITY__CALL_RESULT__GATEKEEPER',
  NO_ANSWER = 'ACTIVITY__CALL_RESULT__NO_ANSWER',
  WRONG_DATA = 'ACTIVITY__CALL_RESULT__WRONG_DATA',
  CALL_LATER = 'ACTIVITY__CALL_RESULT__CALL_LATER',
}

export enum ACTIVITY_MAIN_NOTE_VALUES_LOGIC_ROLE {
  NO = 'ACTIVITY__MAIN_NOTE__NO',
  YES = 'ACTIVITY__MAIN_NOTE__YES',
}

export enum PITCH_DONE_VALUES_LOGIC_ROLE {
  NO = 'ACTIVITY__PITCH_DONE__NO',
  YES = 'ACTIVITY__PITCH_DONE__YES',
}

export enum IS_PINNED_VALUES_LOGIC_ROLE {
  NO = 'ACTIVITY__IS_PINNED__NO',
  YES = 'ACTIVITY__IS_PINNED__YES',
}

export enum BOUNCED_EMAIL_VALUES_LOGIC_ROLE {
  NO = 'ACTIVITY__IS_BOUNCED_EMAIL__NO',
  YES = 'ACTIVITY__IS_BOUNCED_EMAIL__YES',
}

export enum MEETING_MAIN_TYPE_VALUES {
  FIRST_MEETING = 'ACTIVITY__MEETING_MAIN_TYPE__FIRST_MEETING',
  FOLLOW_UP = 'ACTIVITY__MEETING_MAIN_TYPE__FOLLOW_UP',
}

export enum ACTIVITY_MODE {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum CADENCE_TYPES_VALUES_LOGIC_ROLE {
  CONFIGURE = 'ACTIVITY__TYPE_CADENCE__CONFIGURED',
  STARTED = 'ACTIVITY__TYPE_CADENCE__STARTED',
  ENDED = 'ACTIVITY__TYPE_CADENCE__ENDED',
  RESCHEDULE = 'ACTIVITY__TYPE_CADENCE__RESCHEDULE',
  STOPPED = 'ACTIVITY__TYPE_CADENCE__STOPPED',
}

export enum TYPES_STATUS_VALUES_LOGIC_ROLE {
  COMPANY_STATUS_CHANGED = 'ACTIVITY__TYPE_STATUS__COMPANY_STATUS_CHANGED',
  LEAD_STATUS_CHANGED = 'ACTIVITY__TYPE_STATUS__LEAD_STATUS_CHANGED',
  NEW_LEAD_ADDED_TO_OPPORTUNITY = 'ACTIVITY__TYPE_STATUS__NEW_LEAD_ADDED_TO_OPPORTUNITY',
  NEW_LEAD_CREATED = 'ACTIVITY__TYPE_STATUS__NEW_LEAD_CREATED',
  OPPORTUNITY_ASSIGNED = 'ACTIVITY__TYPE_STATUS__OPPORTUNITY_ASSIGNED',
  OPPORTUNITY_CREATED = 'ACTIVITY__TYPE_STATUS__OPPORTUNITY_CREATED',
  OPPORTUNITY_STATUS_CHANGED = 'ACTIVITY__TYPE_STATUS__OPPORTUNITY_STATUS_CHANGED',
}

export enum REPORTED_VALUES_LOGIC_ROLE {
  YES = 'ACTIVITY__REPORTED__YES',
  NO = 'ACTIVITY__REPORTED__NO',
}

export enum IS_AUTOMATED_EMAIL_VALUES {
  YES = 'ACTIVITY__IS_AUTOMATED_EMAIL__YES',
  NO = 'ACTIVITY__IS_AUTOMATED_EMAIL__NO',
}

export enum DIRECTION_VALUES_LOGIC_ROLE {
  OUTGOING = 'ACTIVITY__DIRECTION__OUTGOING',
  INCOMING = 'ACTIVITY__DIRECTION__INCOMING',
  MISSED = 'ACTIVITY__DIRECTION__MISSED',
}

export enum ACTIVITY_TYPES {
  CADENCE = 'Cadence',
  CALL = 'Call',
  EMAIL = 'Email',
  INBOUND = 'Inbound',
  LINKEDIN = 'LinkedIn Message',
  MEETING = 'Meeting',
  NOTE = 'Note',
  STATUS = 'Status',
  CUSTOM_TASK = 'Custom task',
}

export enum ACTIVITY_DIRECTION {
  INCOMING = 'Incoming',
  OUTGOING = 'Outgoing',
  MISSED = 'Missed',
}

export enum DATA_SOURCES {
  WEB_APP = 'DATA_SOURCE__WEB_APP',
  CHROME_EXTENSION = 'DATA_SOURCE__LINKEDIN_CHROME_EXTENSION',
}

export enum CUSTOM_TASK_TYPES {
  WHATSAPP = 'WHATSAPP',
  WHATSAPP_BUSINESS = 'WHATSAPP_BUSINESS',
}

export interface GroupedActivityFeedRequestInterface {
  query: Record<LogicRoleType<BobjectTypes.Activity>, any>;
  queries?: Record<LogicRoleType<BobjectTypes.Activity>, any>[];
  bobjectId: BobjectId['value'];
  pageSize: number;
  index: number;
  dateTimes?: TDateISODate[];
  zoneId: string;
}

export interface GroupedActivityInterface {
  activityType: ACTIVITY_TYPES_VALUES_LOGIC_ROLE;
  customTaskName: string;
  customTaskId: string;
  subject: string;
  body: string;
  activityTime: TDateISO;
  bobjectName: string;
  bobjectId: BobjectId<BobjectTypes.Activity>['value'];
  companyId: BobjectId<BobjectTypes.Company>['value'];
  companyName: string;
  companyAssignedTo: string;
  leadId: BobjectId<BobjectTypes.Lead>['value'];
  leadName: string;
  leadAssignedTo: string;
  opportunityId: BobjectId<BobjectTypes.Opportunity>['value'];
  opportunityName: string;
  opportunityStage: OPPORTUNITY_STATUS_LOGIC_ROLE;
  opportunityAssignedTo: string;
  note: string;
  direction: DIRECTION_VALUES_LOGIC_ROLE;
  callRecording: any;
  callDuration: number;
  callResult: CALL_RESULTS_LOGIC_ROLE;
  meetingDuration: number;
  meetingResult: MEETING_MAIN_TYPE_VALUES;
  meetingTitle: string;
  cadence: string;
  inboundFormName: string;
  bouncedEmail: boolean;
  emailMetadata: string;
  emailTimesClick: string;
  emailTimesOpen: string;
  emailTimesReply: string;
  leadEmail: string;
  statusTitle: string;
  attachments: string;
  attachedFiles: any;
  count: number;
  activityUser: string;
  reported: 'Yes' | 'No';
  casesLinked: CaseDetail[];
  hasCopilot: boolean;
}

interface CaseDetail {
  Id: string;
  CaseNumber: string;
}

export interface GroupedActivityFeedInterface {
  pinned: GroupedActivityInterface[];
  activities: GroupedActivityInterface[];
  hasNext: boolean;
  index: number;
  totalItems: number;
}
