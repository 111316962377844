export const getProgressPercentage = (testRun: TestRun): string => {
  const results = Object.values(testRun?.results || {});

  const total = results.length;
  let processed = 0;

  results.forEach(value => {
    if (value) {
      processed++;
    }
  });
  const percentage = (processed / total) * 100;
  const roundedPercentage = Math.round(percentage);

  return isNaN(roundedPercentage) ? '0%' : `${roundedPercentage}%`;
};
