import { useState } from 'react';

import { useActiveAccountId } from '@bloobirds-it/hooks';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

import { InsightType, State } from '../types';

export type FilterType = {
  state: State;
  insightType: InsightType;
  activityType: string;
  activitySubtype: string;
};

export const useInsights = () => {
  const accountId = useActiveAccountId();
  const [filter, setFilter] = useState({
    state: '',
    insightType: '',
    activityType: '',
    activitySubtype: '',
  });

  const { data, mutate, isLoading } = useSWR('api/insights', async () => {
    return await api.get(`/utils/service/copilot-insights/admin/${accountId}`);
  });

  const { data: dataSettings } = useSWR('api/insights/settings', async () => {
    return await api.get(`/utils/service/copilot-insights/settings`);
  });

  const filterInsights = (data, filter: FilterType) => {
    if (!data) {
      return [];
    }
    if (filter.state) {
      switch (filter.state) {
        case '':
          break;
        default:
          data = data.filter(insight => insight.state === filter.state);
          break;
      }
    }

    if (filter.insightType) {
      switch (filter.insightType) {
        case '':
          break;
        default:
          data = data.filter(insight => insight.insightType === filter.insightType);
          break;
      }
    }

    if (filter.activityType) {
      switch (filter.activityType) {
        case '':
          break;
        default:
          data = data.filter(insight => insight.activityType === filter.activityType);
          break;
      }
    }

    if (filter.activitySubtype) {
      switch (filter.activitySubtype) {
        case '':
          break;
        default:
          data = data.filter(insight => insight.activitySubtype === filter.activitySubtype);
          break;
      }
    }

    return data;
  };

  const nActive = data?.data?.filter(insight => insight.state === 'ACTIVE').length || 0;
  const nCreated = data?.data?.filter(insight => insight.state === 'CREATED').length || 0;

  return {
    isLoading,
    insights: filterInsights(data?.data, filter) || [],
    filter,
    setFilter,
    mutate,
    nActive,
    nCreated: nCreated + nActive,
    nActiveTotal: dataSettings?.data?.maxInsightsEnabled,
    nCreatedTotal: dataSettings?.data?.maxInsightsCreated,
  };
};
