import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTestRuns } from '@bloobirds-it/copilot';
import {
  Button,
  Label,
  Tab,
  TabGroup,
  Text,
  useToasts,
  Tooltip,
  Icon,
} from '@bloobirds-it/flamingo-ui';
import { CopilotInsight, TestRun, TestSet, TestRunStatus } from '@bloobirds-it/types';
import spacetime from 'spacetime';
import { mutate } from 'swr';

import { api } from '../../../../utils/api';
import { usePlayground } from '../../playgroundPages';
import styles from '../../playgroundPages.module.css';
import { ActivityTab } from './activityTab';
import { PromptTab } from './promptTab';

export function TestResults({
  selectedTestSet,
  selectedRun,
}: {
  selectedTestSet: TestSet;
  selectedRun: TestRun;
}) {
  const { insights } = usePlayground();
  const [selectedTab, setSelectedTab] = useState('prompt');
  const { createTestRun } = useTestRuns();
  const { createToast } = useToasts();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground' });

  const selectedInsight: CopilotInsight = insights?.find(
    insight => insight.versionIdentifier === selectedRun?.insight_id,
  );

  async function handleStartTest() {
    try {
      await api.post(`/copilot/playground/test-run/${selectedRun.pk}/start`);
      mutate('/api/test-runs/' + selectedTestSet.pk);
    } catch (error) {
      console.log('error', error);
    }
  }

  async function updatePrompt(copilotInsight: CopilotInsight, createTestRunToo: boolean) {
    try {
      const { data: updatedInsight } = await api.patch(
        '/utils/service/copilot-insights/prompt/' + copilotInsight.versionIdentifier,
        { prompt: copilotInsight.prompt },
      );
      mutate('api/insights');
      if (!createTestRunToo) {
        createToast({ type: 'success', message: t('promptTab.saveDropdown.success.newVersion') });
        return;
      }

      try {
        const response = await createTestRun({
          testSetId: selectedTestSet?.pk,
          insightId: updatedInsight.id,
        });
        mutate('/api/test-runs/' + selectedTestSet.pk);
        createToast({
          type: 'success',
          message: t('promptTab.saveDropdown.success.newVersionAndTestRun'),
        });
      } catch (testRunError) {
        // Version created, but Test run creation failed
        createToast({
          type: 'error',
          message: t('promptTab.saveDropdown.errors.newVersionAndTestRun'),
        });
        throw testRunError;
      }
    } catch (updateError) {
      // Create prompt version failed, didnt atempt to create testRun
      createToast({ type: 'error', message: t('promptTab.saveDropdown.errors.newVersion') });
      throw updateError;
    }
  }

  let selectedResults = !selectedRun?.summarized_results
    ? 'N/A'
    : `${
        selectedRun.summarized_results.total -
        selectedRun.summarized_results.without_expected_result
      } / ${selectedRun.summarized_results.total}`;

  if (
    selectedInsight?.insightType === 'GENERATION' &&
    selectedRun.status === TestRunStatus.NOT_STARTED
  ) {
    selectedResults = t('header.selectedResultsPlaceholder');
  }

  const showTooltipForSelectedResults =
    selectedInsight?.insightType === 'GENERATION' &&
    selectedRun?.status === TestRunStatus.NOT_STARTED;

  return (
    <div className={styles.testRun_container}>
      {selectedRun ? (
        <>
          <div className={styles.test_results_header}>
            <div className={styles.test_header}>
              <div className={styles.test_results_header_title}>
                <Text size="l" color="peanut" weight="bold">
                  Run {spacetime(selectedRun?.created_at).format('nice')}
                </Text>
                <Label size="small" color="lightestPurple">
                  {t(`status.${selectedRun?.status}`)}
                </Label>
              </div>
              <div>
                {selectedRun?.status === 'NOT_STARTED' && (
                  <Button
                    size="small"
                    color="peanut"
                    variant="secondary"
                    iconLeft="play"
                    onClick={handleStartTest}
                  >
                    {t('startTest')}
                  </Button>
                )}
              </div>
            </div>
            <div className={styles.test_results_header_labels}>
              <div className={styles.test_results_header_label}>
                <Text size="xs" weight="bold">
                  {t('insight')}:
                </Text>
                <Text size="xs" color="softPeanut">
                  {selectedInsight?.title}
                </Text>
              </div>
              <div className={styles.test_results_header_label}>
                <Text size="xs" weight="bold">
                  {t('header.testSet')}:
                </Text>
                <Text size="xs" color="softPeanut">
                  {selectedTestSet?.name}
                </Text>
              </div>
              <div className={styles.test_results_header_label}>
                <Text size="xs" weight="bold">
                  {t('version')}:
                </Text>
                <Text size="xs" color="softPeanut">
                  {selectedInsight?.version}
                </Text>
              </div>
              <div className={styles.test_results_header_label}>
                <Text size="xs" weight="bold">
                  {t('header.activityType')}:
                </Text>
                <Label size="small">{selectedTestSet?.activity_type}</Label>
              </div>
              <div className={styles.test_results_header_label}>
                <Text size="xs" weight="bold">
                  {t('header.selectedResults')}:
                </Text>
                <Label size="small">{selectedResults}</Label>
                {showTooltipForSelectedResults && (
                  <Tooltip title={t('header.selectedResultsPlaceholderTooltip')} position="top">
                    <Icon size={16} name="infoFilled" color="bloobirds" />
                  </Tooltip>
                )}
              </div>
              <div className={styles.test_results_header_label}>
                <Text size="xs" weight="bold">
                  {t('header.activitySubType')}:
                </Text>
                <Text size="xs" color="softPeanut">
                  {selectedInsight?.activitySubtype || 'N/A'}
                </Text>
              </div>
            </div>
          </div>
          <div className={styles.test_results_tab_header}>
            <TabGroup value={selectedTab} onClick={setSelectedTab}>
              <Tab color="peanut" name="Prompt" size="s" iconLeft="textEditor" iconLeftSize={12}>
                <PromptTab
                  selectedRun={selectedRun}
                  onSave={updatePrompt}
                  selectedInsight={selectedInsight}
                />
              </Tab>
              <Tab
                color="peanut"
                name={t('activityTab.title')}
                size="s"
                iconLeft="list"
                iconLeftSize={12}
              >
                <ActivityTab
                  selectedInsight={selectedInsight}
                  selectedRun={selectedRun}
                  selectedTestSet={selectedTestSet}
                />
              </Tab>
              {/*<Tab color="peanut" name="Result" size="s" iconLeft="barchart" iconLeftSize={12}>
                <ResultTab />
              </Tab>*/}
            </TabGroup>
          </div>
        </>
      ) : (
        <div className={styles.tests_result_empty}>
          <span>👈</span>
          <Text size="s" color="peanut" weight="heavy" align="center">
            {t('detailsPlaceholder.title')}
          </Text>
          <Text size="xs" color="peanut" align="center">
            {t('detailsPlaceholder.subtitle')}
          </Text>
        </div>
      )}
    </div>
  );
}
