import { useForm } from 'react-hook-form';

import { useStatus } from '@bloobirds-it/hooks';
import { Bobject, BobjectTypes, OpportunityBobject } from '@bloobirds-it/types';
import { WizardsModalParams, ButtonsStepConfig, STEPS } from '@bloobirds-it/wizard-modal-context';
import { TFunction } from 'i18next';

import { useNoteData } from '../hooks/useNoteData';
import { getIsAnyEmailOrWhatsappOrToday } from '../hooks/useStatusNoteActions.utils';

export interface StatusNoteActionContextType
  extends ReturnType<typeof useStatus>,
    WizardsModalParams,
    ReturnType<typeof useNoteData>,
    ReturnType<typeof getIsAnyEmailOrWhatsappOrToday> {
  activity: Bobject<BobjectTypes.Activity>;
  activityCompany: Bobject<BobjectTypes.Company>;
  activityLead: Bobject<BobjectTypes.Lead>;
  activityOpportunity: OpportunityBobject;
  bobject;
  bobjectType;
  buttonsConfig: ButtonsStepConfig;
  formMethods: ReturnType<typeof useForm>;
  handleBack: () => void;
  handleErrors: [any, () => void];
  handleNext: () => () => void;
  handleSelectedReason: [any, (value: any) => void];
  handleSelectedStatus: [any, (value: any) => void];
  handleSelectedUser: [any, (value: any) => void];
  handleSkip: () => void;
  handleUpdateStatus: (values: any) => Promise<any>;
  hasEmailTask: boolean;
  hasNoStatusPlanEnabled: boolean;
  hasSaved: boolean;
  hasStartedCadence;
  hasWhatsappTask: boolean;
  isAssigned;
  isInactive: boolean;
  loading: boolean;
  machineContext?: any;
  note: any;
  saveNote: any;
  send: (event: any, params?: any) => void;
  setLoading: (value: boolean) => void;
  setNote: (value: any) => void;
  statusHasChangedState: [boolean, (value: boolean) => void];
  step: keyof typeof STEPS;
  t: TFunction;
  taskForToday: any;
}

export enum TASK_STATE {
  IDLE,
  COMPLETING,
  COMPLETED,
}
