import React, { useState } from 'react';

import { Insight } from '@bloobirds-it/types';

import InsightInfo from '../components/insightInfo/insightInfo';
import InsightList from '../components/insightList/insightList';
import styles from '../playgroundPages.module.css';

const Insights = () => {
  const [selectedInsight, setSelectedInsight] = useState<Insight>();

  return (
    <div className={styles.playground_content}>
      <InsightList setSelectedInsight={setSelectedInsight} selectedInsight={selectedInsight} />
      <InsightInfo selectedInsight={selectedInsight} />
    </div>
  );
};

export default Insights;
