import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Icon, Text } from '@bloobirds-it/flamingo-ui';

import styles from '../../playgroundPages.module.css';

const TestSetsEmpty = ({ handleAddTestSet }: { handleAddTestSet: () => void }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.testSet' });

  return (
    <div className={styles.test_empty}>
      <Icon name="searchNone" size={32} color="softPeanut" />
      <Text size="s" color="peanut" weight="bold">
        {t('noResults')}
      </Text>
      <Text size="xs" color="peanut" align="center">
        {t('noResultsDescription')}
      </Text>
      <Button
        size="small"
        color="lightPurple"
        onClick={handleAddTestSet}
        uppercase={false}
        iconLeft="add"
        expand
      >
        {t('noResultsButton')}
      </Button>
      <Text size="xs" color="peanut" align="center" className={styles.test_empty_info}>
        <Trans
          t={t}
          i18nKey="noResultsDescriptionInfo"
          components={[
            <a
              key="0"
              href={'https://support.bloobirds.com/hc/en-us'}
              target="_blank"
              rel="noreferrer"
            >
              {''}
            </a>,
          ]}
        ></Trans>
      </Text>
    </div>
  );
};

export default TestSetsEmpty;
