import { ACTIVITY_FIELDS_LOGIC_ROLE, ACTIVITY_TYPES, Bobject } from '@bloobirds-it/types';
import { api, getTextFromLogicRole } from '@bloobirds-it/utils';
import { atom, useRecoilState, useResetRecoilState } from 'recoil';

import { CheckTestSet, CheckTestSetResponse } from '../typings/playground';

interface SendToPlaygroundModalState {
  isOpen: boolean;
  bobjects: null | Bobject[];
  typeMismatch: boolean;
  checkingActivities: boolean;
  checkedActivities: null | {
    activitiesWithTranscript: number;
    activitiesWithoutTranscript: number;
  };
  type: string;
}

const sendToPlaygroundModalAtom = atom<SendToPlaygroundModalState>({
  key: 'sendToPlaygroundModal',
  default: {
    isOpen: false,
    bobjects: null,
    typeMismatch: false,
    checkingActivities: false,
    checkedActivities: null,
    type: null,
  },
});

const allHaveSameType = (bobjects: Bobject[]) => {
  const targetType = getTextFromLogicRole(bobjects[0], ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);
  return bobjects.every(bobject => {
    const type = getTextFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);
    return targetType === type;
  });
};

const useSendToPlaygroundModal = () => {
  const [state, setState] = useRecoilState(sendToPlaygroundModalAtom);
  const resetState = useResetRecoilState(sendToPlaygroundModalAtom);

  const closeSendToPlaygroundModal = () => {
    resetState();
  };

  const checkActivities = (data: CheckTestSet) => {
    return api
      .post<CheckTestSetResponse>('/copilot/playground/test-set/check', data)
      .then(res => res.data);
  };

  const openSendToPlaygroundModal = (bobjects: Bobject[]) => {
    const allMatchType = allHaveSameType(bobjects);

    const state: SendToPlaygroundModalState = {
      isOpen: true,
      bobjects,
      typeMismatch: !allMatchType,
      checkingActivities: true,
      checkedActivities: null,
      type: getTextFromLogicRole(bobjects[0], ACTIVITY_FIELDS_LOGIC_ROLE.TYPE),
    };

    checkActivities({ activities: bobjects.map(b => b.id.objectId) }).then(checkRes =>
      setState({
        ...state,
        checkedActivities: {
          activitiesWithTranscript: checkRes.activitiesWithTranscript.length,
          activitiesWithoutTranscript:
            checkRes.activitiesChecked.length - checkRes.activitiesWithTranscript.length,
        },
        checkingActivities: false,
      }),
    );

    setState(state);
  };

  return {
    ...state,
    openSendToPlaygroundModal,
    closeSendToPlaygroundModal,
  };
};

export default useSendToPlaygroundModal;
