import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { InsightFormModal, useActivityFieldsInfo } from '@bloobirds-it/copilot';
import {
  Button,
  Chip,
  ChipGroup,
  Icon,
  Item,
  Label,
  Select,
  Skeleton,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { Insight, ACTIVITY_TYPE_OPTIONS, INSIGHT_TYPE_OPTIONS } from '@bloobirds-it/types';
import { motion } from 'framer-motion';

import { useInsights } from '../../hooks/useInsights';
import styles from '../../playgroundPages.module.css';
import { QuickFilters, State } from '../../types';
import InsightCard from './insightCard';

const InsightsList = ({
  selectedInsight,
  setSelectedInsight,
}: {
  selectedInsight: Insight;
  setSelectedInsight: (value: Insight) => void;
}) => {
  const [openCreateInsight, setOpenCreateInsight] = useState(false);
  const [filtersEnabled, setFiltersEnabled] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const { t: tBase } = useTranslation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'ai.insightList',
  });
  const { t: tState } = useTranslation('translation', { keyPrefix: 'ai.insightState' });

  const {
    insights,
    isLoading,
    filter,
    setFilter,
    nActive,
    nCreated,
    nActiveTotal,
    nCreatedTotal,
  } = useInsights();
  const { meetingTypes = [] } = useActivityFieldsInfo();
  const activitySubtypes = filter.activityType === 'Meeting' ? meetingTypes : [];
  const activityTypes = ACTIVITY_TYPE_OPTIONS(tBase);
  const insightTypes = INSIGHT_TYPE_OPTIONS(tBase);

  useEffect(() => {
    if (firstLoad) setFirstLoad(false);
  }, []);

  const thereAreFilters = Object.values(filter).some(value => value !== '');
  const isEmptyList = (!insights || (insights.length === 0 && !isLoading)) && !thereAreFilters;
  const isEmptyFilterList = (!insights || (insights.length === 0 && !isLoading)) && thereAreFilters;

  return (
    <>
      <div className={styles.insightList_container}>
        <div className={styles.insightList_header}>
          <div>
            <Icon name="insights" size={20} color="softPeanut" />
            <Text size="s" color="softPeanut" weight="bold">
              {t('title')}
            </Text>
          </div>
          <div>
            {!isEmptyList && (
              <Button
                variant="secondary"
                color="bloobirds"
                size="small"
                uppercase={false}
                iconLeft="plus"
                onClick={() => setOpenCreateInsight(true)}
                disabled={nCreatedTotal >= nCreated}
              >
                {t('new')}
              </Button>
            )}
            <Button
              className={styles.insightList_filters_button}
              iconLeft={!filtersEnabled ? 'slidersHor' : 'cross'}
              color="bloobirds"
              variant={filtersEnabled ? 'primary' : 'clear'}
              onClick={() => setFiltersEnabled(prevState => !prevState)}
            />
          </div>
        </div>
        {filtersEnabled && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className={styles.insightList_filters}
          >
            <Select
              size="small"
              value={filter?.insightType || ''}
              onChange={value => setFilter(prevFilter => ({ ...prevFilter, insightType: value }))}
              placeholder={t('filterInsightType')}
            >
              <Item value="" key="all">
                {t('all')}
              </Item>
              {insightTypes.map(insightType => (
                <Item value={insightType.id} key={insightType.id}>
                  {insightType.name}
                </Item>
              ))}
            </Select>
            <div className={styles.insightList_filters_row}>
              <Select
                size="small"
                value={filter?.activityType || ''}
                onChange={value =>
                  setFilter(prevFilter => ({ ...prevFilter, activityType: value }))
                }
                placeholder={t('filterActivityType')}
              >
                {activityTypes?.map(activityType => (
                  <Item
                    value={activityType.id === 'All' ? '' : activityType.id}
                    key={activityType.id}
                  >
                    {activityType.name}
                  </Item>
                ))}
              </Select>
              <Select
                size="small"
                value={filter?.activitySubtype || ''}
                onChange={value =>
                  setFilter(prevFilter => ({ ...prevFilter, activitySubtype: value }))
                }
                placeholder={t('filterActivitySubtype')}
              >
                <Item value="" key="all">
                  {t('all')}
                </Item>
                {activitySubtypes?.map(activitySubtype => (
                  <Item value={activitySubtype.id} key={activitySubtype.id}>
                    {activitySubtype.name}
                  </Item>
                ))}
              </Select>
            </div>
          </motion.div>
        )}
        {!filtersEnabled && (
          <motion.div
            initial={firstLoad ? {} : { opacity: 0, y: 20 }}
            animate={firstLoad ? {} : { opacity: 1, y: 0 }}
            exit={firstLoad ? {} : { opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className={styles.insightsList_body}
          >
            <div className={styles.insightList_limits}>
              <div>
                <Icon name="play" size={14} color="softPeanut" />
                <Text size="s" weight="bold">
                  {t('active')}:
                </Text>
                <Label size="small" color="lightPeanut" textColor="peanut">
                  {nActive} / {nActiveTotal}
                </Label>
              </div>
              <div>
                <Icon name="edit" size={14} color="softPeanut" />
                <Text size="s" weight="bold">
                  {t('created')}:
                </Text>
                <Label size="small" color="lightPeanut" textColor="peanut">
                  {nCreated} / {nCreatedTotal}
                </Label>
              </div>
            </div>
            <div className={styles.insightList_info}>
              <Text size="xs" color="softPeanut">
                {t('info')}
              </Text>
            </div>
          </motion.div>
        )}
        <div className={styles.insightList_quickfilters}>
          <ChipGroup
            value={filter.state || 'ALL'}
            onChange={value => {
              setFilter(prevFilter => ({
                ...prevFilter,
                state: value === 'ALL' ? '' : (value as State),
              }));
            }}
          >
            {QuickFilters.map(({ id, name }) => (
              <Chip size="small" value={id} key={id} disabled={isEmptyList}>
                {tState(name.toLowerCase())}
              </Chip>
            ))}
          </ChipGroup>
        </div>
        {isLoading && (
          <div className={styles.insightList_skeleton}>
            {[...Array(5)].map((_, index) => (
              <Skeleton key={index} variant="rect" height={90} width="100%" />
            ))}
          </div>
        )}
        {insights && insights.length > 0 && (
          <div className={styles.insightList_list}>
            {insights.map((insight: Insight) => (
              <InsightCard
                key={insight.id}
                insight={insight}
                isSelected={insight.id === selectedInsight?.id}
                handleClick={() => {
                  setSelectedInsight(insight);
                }}
              />
            ))}
          </div>
        )}
        {isEmptyList && (
          <div className={styles.insightList_empty}>
            <Icon name="insights" size={32} color="peanut" />
            <div>
              <Text size="xs" color="softPeanut" align="center">
                {t('emptyList')}
              </Text>
              <Text size="xs" color="softPeanut" align="center">
                {t('emptyListDesc')}
              </Text>
            </div>
            <Button
              variant="primary"
              color="bloobirds"
              uppercase={false}
              onClick={() => setOpenCreateInsight(true)}
            >
              {t('newInsight')}
            </Button>
          </div>
        )}
        {isEmptyFilterList && (
          <div className={styles.insightList_empty}>
            <Icon name="searchNone" size={32} color="peanut" />
            <Text size="xs" color="softPeanut" align="center">
              {t('emptyFilterList')}
            </Text>
          </div>
        )}
      </div>
      {openCreateInsight && <InsightFormModal onClose={() => setOpenCreateInsight(false)} />}
    </>
  );
};

export default InsightsList;
