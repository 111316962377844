import { api } from '@bloobirds-it/utils';

type CreateTestRunBody = {
  testSetId: string;
  insightId: string;
};

export const useTestRuns = () => {
  const createTestRun = (body: CreateTestRunBody) => {
    return api.post('/copilot/playground/test-run', body);
  };
  const getInsightVersions = (versionId: string) =>
    api.get('/utils/service/copilot-insights/version/' + versionId);

  return {
    createTestRun,
    getInsightVersions,
  };
};
